import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {LegalNoticeDTO, PaginatedEntityList} from "../../api/dtos";

export interface NoticeState {
    allPaginatedLegalNotices: PaginatedEntityList<LegalNoticeDTO> | null;
    loadLegalNoticesTaskStatus: AsyncTaskStatus | null;
}

const initialState: NoticeState = {
    allPaginatedLegalNotices: null,
    loadLegalNoticesTaskStatus: null,
};

const noticeSlice = createSlice({
    name: 'notice',
    initialState,
    reducers: {
        setLoadLegalNoticesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadLegalNoticesTaskStatus = action.payload;
        },
        setAllPaginatedLegalNotices: (state, action: PayloadAction<PaginatedEntityList<LegalNoticeDTO> | null>) => {
            state.allPaginatedLegalNotices = action.payload;
        }
    }
});

export const {
    setAllPaginatedLegalNotices,
    setLoadLegalNoticesTaskStatus,
} = noticeSlice.actions;

export default noticeSlice.reducer;
