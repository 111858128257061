import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React, {useState} from "react";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import {tr} from "date-fns/locale";
import CustomDialog from "../../components/CustomDialog";

interface AddTrialDateDialogProps {
    handleClose: () => void;
    addTrialDate: (result: any) => void;
}

interface FormData {
    date: Date;
}

export const AddTrialDateDialog: React.FC<AddTrialDateDialogProps> = ({handleClose, addTrialDate}) => {

    const handleDateChange = (name: any) => (newDate: any) => {
        setFormData({
            ...formData,
            [name]: newDate
        });
    };

    const [formData, setFormData] = useState<FormData>({
        date: new Date()
    });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        addTrialDate(moment(formData.date).format("YYYY-MM-DDTHH:mm:ss"));
        handleClose();
    };

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}>
            <DialogTitle>Duruşma Tarihi Seç</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="Yeni Duruşma Tarihi"
                            name="date"
                            value={formData.date}
                            onChange={handleDateChange('date')}
                            // @ts-ignore
                            renderInput={(params: any) => <TextField {...params} fullWidth/>}/>
                    </LocalizationProvider>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Ekle</Button>
            </DialogActions>
        </CustomDialog>
    );
}
