import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {setLoginTaskStatus} from '../features/auth/authSlice';
import {Link, useNavigate} from 'react-router-dom';
import {AsyncTaskStatusType} from "../shared/dtos";
import themeConfig from '../theme/configs/themeConfig'

// MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import {styled, useTheme} from '@mui/material/styles'
import MuiCard, {CardProps} from '@mui/material/Card'
import InputAdornment from '@mui/material/InputAdornment'
import MuiFormControlLabel, {FormControlLabelProps} from '@mui/material/FormControlLabel'
// ** Icons Imports
import EyeOutline from 'mdi-material-ui/EyeOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import BlankLayout from "../theme/@core/layouts/BlankLayout";
import {authenticateUserAction} from "../api/auth";
import {unwrapResult} from "@reduxjs/toolkit";

interface State {
    username: string;
    password: string;
    showPassword: boolean;
}

// ** Styled Components
const Card = styled(MuiCard)<CardProps>(({theme}) => ({
    [theme.breakpoints.up('sm')]: {width: '28rem'}
}))

const LinkStyled = styled('a')(({theme}) => ({
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: theme.palette.primary.main
}))

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({theme}) => ({
    '& .MuiFormControlLabel-label': {
        fontSize: '0.875rem',
        color: theme.palette.text.secondary
    }
}))

const Login = () => {
    // ** State
    const [values, setValues] = useState<State>({
        username: '',
        password: '',
        showPassword: false
    })

    // ** Hook
    const theme = useTheme()

    const handleChange = (prop: keyof State) => (event: any) => {
        setValues({...values, [prop]: event.target.value})
    }

    const handleClickShowPassword = () => {
        setValues({...values, showPassword: !values.showPassword})
    }

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault()
    }

    const dispatch: any = useDispatch();
    const navigate = useNavigate();


    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission if needed
            handleLogin(event); // Call your login handler
        }
    };


    const handleLogin = async (e: React.FormEvent) => {

        try {
            const resultAction = await dispatch(authenticateUserAction({
                email: values.username,
                password: values.password
            }));

            const token = unwrapResult(resultAction);

            if (token) {
                navigate('/');
            } else {
                throw new Error('Geçersiz giriş bilgileri');
            }
        } catch (error: any) {
            dispatch(setLoginTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
        }
    };

    return (
        <BlankLayout>
            <Box className='content-center'>
                <Card sx={{zIndex: 1}}>
                    <CardContent sx={{padding: theme => `${theme.spacing(12, 9, 7)} !important`}}>
                        <Box sx={{mb: 8, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img height={50} width={50} src='/avatar.png'/>
                            <Typography
                                variant='h6'
                                sx={{
                                    ml: 3,
                                    lineHeight: 1,
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                    fontSize: '1.5rem !important'
                                }}
                            >
                                {themeConfig.templateName}
                            </Typography>
                        </Box>
                        <Box sx={{mb: 6}}>
                        </Box>
                        <form noValidate autoComplete='off' onKeyDown={handleKeyDown} onSubmit={handleLogin}>
                            <FormControl fullWidth sx={{mb: 4}}>
                                <InputLabel htmlFor='auth-login-password'>Email</InputLabel>
                                <OutlinedInput
                                    label='User Name'
                                    value={values.username}
                                    id='auth-login-username'
                                    onChange={handleChange('username')}
                                    type="text"/>
                            </FormControl>
                            <FormControl fullWidth sx={{mb: 4}}>
                                <InputLabel htmlFor='auth-login-password'>Şifre</InputLabel>
                                <OutlinedInput
                                    label='Password'
                                    value={values.password}
                                    id='auth-login-password'
                                    onChange={handleChange('password')}
                                    type={values.showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                edge='end'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                aria-label='toggle password visibility'>
                                                {values.showPassword ? <EyeOutline/> : <EyeOffOutline/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <Box
                                sx={{
                                    mb: 4,
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <FormControlLabel control={<Checkbox/>} label='Beni Hatırla'/>
                                <Link to='/'>
                                    <LinkStyled onClick={e => e.preventDefault()}>Şifremi Unuttum</LinkStyled>
                                </Link>
                            </Box>
                            <Button
                                fullWidth
                                size='large'
                                variant='contained'
                                sx={{marginBottom: 7}}
                                onClick={handleLogin}>
                                Giriş Yap
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Box>
        </BlankLayout>
    );
};

export default Login;