import {configureStore} from '@reduxjs/toolkit';
import authSlice, {AuthState} from './features/auth/authSlice';
import settingsSlice, {SettingsState} from "./features/settings/settingsSlice";
import appUserSlice, {AppUserState} from "./features/app-user/appUserSlice";
import legalCaseSlice, {LegalCaseState} from "./features/legal-case/legalCaseSlice";
import taskSlice, {TaskState} from "./features/task/taskSlice";
import noticeSlice, {NoticeState} from "./features/notices/legalNotice";
import appDataSlice, {AppDataState} from "./features/appData/appDataSlice";

export interface AppState {
    auth: AuthState;
    settings: SettingsState;
    appUser: AppUserState;
    legalCase: LegalCaseState;
    task: TaskState;
    notices: NoticeState;
    appData: AppDataState;
}

const appReducer = {
    auth: authSlice,
    settings: settingsSlice,
    appUser: appUserSlice,
    legalCase: legalCaseSlice,
    task: taskSlice,
    notices: noticeSlice,
    appData: appDataSlice
};

const store = configureStore({
    reducer: appReducer
});

export default store;
