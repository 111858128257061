import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    Fab,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {getLegalNoticesAction} from "../../api/settings";
import {setAllPaginatedLegalNotices, setLoadLegalNoticesTaskStatus,} from "../../features/notices/legalNotice";
import {AddNoticeLegalCasePopup} from "../legal-case-actions/legal-case-actions";
import {useNavigate, useSearchParams} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";

moment.locale("tr");

export interface LegalCaseNoticesProps {
    id: number;
}

export const LegalCaseNotices: React.FC<LegalCaseNoticesProps> = ({id}) => {
    const [searchParams] = useSearchParams();
    const dispatch: any = useDispatch();
    const page = Number(searchParams.get("page") || "1");
    const allPaginatedLegalNotices = useSelector(
        (state: AppState) => state.notices.allPaginatedLegalNotices);
    const [isPopupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    const pageRef = useRef(page);
    const [textFilter, setTextFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);

    useEffect(() => {
        dispatch(getLegalNoticesAction({
                legalCaseId: id,
                textFilter,
                startDateFilter,
                endDateFilter,
                page,
            })
        );
        return () => {
            dispatch(setAllPaginatedLegalNotices(null));
            dispatch(setLoadLegalNoticesTaskStatus(null));
        };
    }, []);

    useEffect(() => {
        if (page === 1) {
            dispatch(getLegalNoticesAction({
                    legalCaseId: id,
                    textFilter,
                    startDateFilter,
                    endDateFilter,
                    page
                })
            );
        } else {
            navigate("?page=1");
        }
    }, [textFilter, startDateFilter, endDateFilter]);

    useEffect(() => {
        if (pageRef.current !== page) {
            dispatch(getLegalNoticesAction({legalCaseId: id, page}));
        }
        pageRef.current = page;
    }, [page]);

    const handleNoticeAdded = () => {
        if (page === 1) {
            dispatch(getLegalNoticesAction({legalCaseId: id, page: 1}));
        } else {
            navigate("?page=1");
        }
    };

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        navigate("?page=" + value);
    };

    const applyTextFilter = (e: { target: { value: any } }) => {
        const inputTextSearch = e.target.value;
        setTextFilter(inputTextSearch);
    };

    const applyStartDateFilter = (date: Date | null) => {
        setStartDateFilter(date);
    };

    const applyEndDateFilter = (date: Date | null) => {
        setEndDateFilter(date);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginLeft: "auto", marginBottom: 5}}
                    onClick={() => setPopupOpen(true)}>
                    <AddIcon/>
                </Fab>
            </Box>
            <Paper elevation={3} sx={{p: 3}}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="legal notices table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{width: "25%"}}>
                                    <TextField
                                        style={{minWidth: "210px"}}
                                        label="Tebligat Başlığı"
                                        variant="outlined"
                                        size="small"
                                        onChange={applyTextFilter}/>
                                </TableCell>
                                <TableCell style={{width: "25%"}}/>
                                <TableCell style={{width: "25%"}}/>
                                <TableCell style={{minWidth: "570px"}}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        adapterLocale={tr}>
                                        <DatePicker
                                            label="Başlangıç Tarihi"
                                            value={startDateFilter}
                                            onChange={applyStartDateFilter}
                                            // @ts-ignore
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth style={{minWidth: "210px"}}/>
                                            )}/>
                                        <DatePicker
                                            sx={{marginLeft: "10px"}}
                                            label="Bitiş Tarihi"
                                            value={endDateFilter}
                                            onChange={applyEndDateFilter}
                                            // @ts-ignore
                                            renderInput={(params) => (
                                                <TextField {...params} fullWidth style={{minWidth: "210px"}}/>
                                            )}/>
                                    </LocalizationProvider>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>TEBLİGAT BAŞLIĞI</TableCell>
                                <TableCell>TEBLİGAT AÇIKLAMASI</TableCell>
                                <TableCell>TEBLİĞ TARİHİ</TableCell>
                                <TableCell>SON TARİH</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allPaginatedLegalNotices?.entity.map((notice, index) => (
                                <TableRow key={index}>
                                    <TableCell>{notice.title}</TableCell>
                                    <TableCell>{notice.description}</TableCell>
                                    <TableCell>
                                        {notice.arrivalDate
                                            ? moment.unix(notice.arrivalDate as any).format("DD MMMM YYYY")
                                            : "-"}
                                    </TableCell>
                                    <TableCell>
                                        {notice.dueDate
                                            ? moment.unix(notice.dueDate as any).format("DD MMMM YYYY")
                                            : "-"}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {allPaginatedLegalNotices?.totalNumberPages != null &&
                    allPaginatedLegalNotices.totalNumberPages > 1 && (
                        <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                            <Pagination
                                page={page}
                                count={allPaginatedLegalNotices.totalNumberPages}
                                defaultPage={1}
                                onChange={handleChange}/>
                        </div>
                    )}
                {isPopupOpen && (
                    <AddNoticeLegalCasePopup
                        caseId={id}
                        onClose={() => setPopupOpen(false)}
                        onNoticeAdded={handleNoticeAdded}/>
                )}
            </Paper>
        </>
    );
};
