import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate, useSearchParams} from "react-router-dom";
import {createCourtAction, getAllCourtsAction, getLoadCitiesAction} from "../../api/settings";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import ErrorText from "../../components/ErrorText";
import {FieldErrors} from "../../shared/dtos";
import {AddEditCourtDTO, CourtType, getPresentableCourtType} from "../../api/dtos";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import {setCreateCourtTaskStatus} from "../../features/settings/settingsSlice";
import {AppState} from "../../store";
import CustomDialog from "../../components/CustomDialog";

interface CreateCourtHouseProps {
    handleClose: () => void;
    page: number;
}

interface AddCreateCourtFormData {
    cityId: number | null;
    courtHouseId: number | null;
    courtId: number | null;
    courtType: CourtType | null;
    name: string;
}

const fieldVariant = "outlined";

export const CreateCourt: React.FC<CreateCourtHouseProps> = ({handleClose}) => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const allCitiesLookup = useSelector((state: AppState) => state.settings.allCitiesLookup);
    const allCourtHouses = useSelector((state: AppState) => state.settings.allCourtHouses);
    const [errors, setErrors] = useState<FieldErrors>({});
    const defaultFormData: AddCreateCourtFormData = {
        cityId: null,
        courtHouseId: null,
        courtId: null,
        courtType: null,
        name: ""
    };
    const [formData, setFormData] = useState<AddCreateCourtFormData>(defaultFormData);

    useEffect(() => {
        dispatch(getLoadCitiesAction());

        return () => {
            dispatch(setCreateCourtTaskStatus(null));
        }
    }, [dispatch]);

    const handleChangeFormData = (event: any) => {
        const {name, value, type, checked} = event.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                // @ts-ignore
                [name]: Array.from(event.target.selectedOptions, (option) => option.value)
            });
        } else {
            let sideEffects = {};
            if (name === "cityId") {
                sideEffects = {
                    courtId: null,
                    courtHouseId: null
                };
            }
            if (name === "courtHouseId") {
                sideEffects = {
                    courtId: null
                };
            }
            setFormData({
                ...formData,
                [name]: value,
                ...sideEffects
            });
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const dto: AddEditCourtDTO = {
            name: formData.name,
            type: formData.courtType!,
            courtHouseId: formData.courtHouseId!
        }

        try {
            await dispatch(createCourtAction(dto));
            handleClose();
            if (page === 1) {
                dispatch(getAllCourtsAction());
            } else {
                navigate("");
            }
        } catch (error) {
            console.error("Form gönderiminde bir hata oluştu:", error);
        }
    };

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Mahkeme Oluştur</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleChangeFormData}/>
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="court-type-label">Mahkeme Türü <span>*</span></InputLabel>
                    <Select
                        labelId="court-type-label"
                        id="courtType"
                        name="courtType"
                        label="Mahkeme Türü"
                        required
                        fullWidth
                        variant={fieldVariant as TextFieldVariants}
                        value={formData.courtType}
                        onChange={handleChangeFormData}>
                        {Object.keys(CourtType).map((type) => (
                            <MenuItem key={type} value={type}>
                                {getPresentableCourtType(CourtType[type as keyof typeof CourtType])}
                            </MenuItem>
                        ))}

                    </Select>
                    {errors.courtType && (
                        <ErrorText message={errors.courtType}/>
                    )}
                </FormControl>


                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="city-id-label">Şehirler <span>*</span></InputLabel>
                    <Select
                        labelId="city-label"
                        id="cityId"
                        name="cityId"
                        label="Şehirler"
                        required
                        fullWidth
                        variant={fieldVariant as TextFieldVariants}
                        value={formData.cityId}
                        onChange={handleChangeFormData}>
                        {allCitiesLookup?.map((cityLookup: any) => (
                            <MenuItem key={cityLookup.id} value={cityLookup.id}>
                                {cityLookup.value}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.cities && (
                        <ErrorText message={errors.cities}/>
                    )}
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="court-house-id-label">Adliye <span>*</span></InputLabel>
                    <Select
                        labelId="court-house-id-label"
                        required
                        id="courtHouseId"
                        name="courtHouseId"
                        label="Adliye"
                        fullWidth
                        variant={fieldVariant as TextFieldVariants}
                        value={formData.courtHouseId}
                        onChange={handleChangeFormData}>
                        {allCourtHouses?.filter((courtHouse) => courtHouse.city.plateId === formData.cityId)
                            .map((filteredCourt) => (
                                <MenuItem key={filteredCourt.id} value={filteredCourt.id}>
                                    {filteredCourt.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
