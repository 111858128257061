import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {createTaskAction} from "../../api/task";
import {AddTaskDTO, TaskPriority, TaskType} from "../../api/dtos";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import ErrorText from "../../components/ErrorText";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import {AppState} from "../../store";
import {getAllUsersAction} from "../../api/appUser";
import {setCreateTaskTaskStatus} from "../../features/task/taskSlice";
import CustomDialog from "../../components/CustomDialog";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

interface CreateTaskDialogProps {
    handleClose: () => void;
    legalCaseId: number | null;
    handleTaskAdded: () => void;
}

const fieldVariant = "outlined"

export const CreateTaskDialog: React.FC<CreateTaskDialogProps> = ({handleClose, legalCaseId, handleTaskAdded}) => {
    const dispatch: any = useDispatch();
    const allUsersLookup = useSelector((state: AppState) => state.appUser.allUsersLookup);
    const createTaskTaskStatus = useSelector((state: AppState) => state.task.createTaskTaskStatus);
    const [title, setTitle] = useState("");
    const [priority, setPriority] = useState<TaskPriority | null>(null);
    const [description, setDescription] = useState("");
    const [assigneeIds, setAssigneeIds] = useState<number[]>([]);
    const [errors, setErrors] = useState<FieldErrors>({});

    useEffect(() => {
        dispatch(getAllUsersAction());
        return () => {
            dispatch(setCreateTaskTaskStatus(null));
        }
    }, [dispatch]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (priority) {
            const formJson: AddTaskDTO = {
                title,
                type: legalCaseId ? TaskType.LegalCaseBound : TaskType.Free,
                priority,
                description,
                legalCaseId,
                assigneeIds
            };

            const result = await dispatch(createTaskAction(formJson));

            if (result) {
                handleClose();
                handleTaskAdded();
            }
        }
    };

    useEffect(() => {
        if (createTaskTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createTaskTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [createTaskTaskStatus]);

    const handleClear = () => setPriority(null);

    const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value);

    const handleChangePriority = (e: SelectChangeEvent<TaskPriority | null>) => setPriority(e.target.value as TaskPriority)

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Görev Ekle</DialogTitle>
            <DialogContent>
                <FormControl sx={{pb: 3, minWidth: "100%"}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="title"
                        name="title"
                        label="Görev Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={title}
                        onChange={handleChangeTitle}/>
                    {errors.title && (
                        <ErrorText message={errors.title}/>
                    )}
                </FormControl>

                <FormControl sx={{pb: 3, minWidth: "100%"}}>
                    <InputLabel id="task-priority-label">Öncelik <span>*</span></InputLabel>
                    <Select
                        labelId="task-priority-label"
                        required
                        id="priority"
                        name="priority"
                        label="Öncelik"
                        fullWidth
                        value={priority}
                        onChange={handleChangePriority}
                        endAdornment={
                            priority && (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleClear}>
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }>
                        <MenuItem value={TaskPriority.Urgent}>Acil</MenuItem>
                        <MenuItem value={TaskPriority.High}>Yüksek</MenuItem>
                        <MenuItem value={TaskPriority.Medium}>Orta</MenuItem>
                        <MenuItem value={TaskPriority.Low}>Düşük</MenuItem>
                    </Select>
                    {errors.priority && (
                        <ErrorText message={errors.priority}/>
                    )}
                </FormControl>

                <FormControl sx={{pb: 3, minWidth: "100%"}}>
                    <TextField
                        required
                        margin="dense"
                        id="description"
                        name="description"
                        label="Açıklama"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}/>
                    {errors.description && (
                        <ErrorText message={errors.description}/>
                    )}
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="assignee-ids-label">Atananlar <span>*</span></InputLabel>
                    <Select
                        required
                        labelId="city-label"
                        id="assignee-ids"
                        name="assigneeIds"
                        label="Atananlar"
                        fullWidth
                        multiple={true}
                        variant={fieldVariant as TextFieldVariants}
                        value={assigneeIds}
                        onChange={(event: any) => {
                            setAssigneeIds(event.target.value);
                        }}>
                        {allUsersLookup?.map((assigneeLookup: any) => (
                            <MenuItem key={assigneeLookup.id} value={assigneeLookup.id}>
                                {assigneeLookup.value}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.assigneeIds && (
                        <ErrorText message={errors.assigneeIds}/>
                    )}
                </FormControl>
                {createTaskTaskStatus && createTaskTaskStatus.type === AsyncTaskStatusType.Error &&
                    createTaskTaskStatus.errorMessage && (
                        <ErrorText type="form" message={createTaskTaskStatus.errorMessage}/>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
