import React, {useEffect, useState} from "react";
import {Pagination, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {getAllPaginatedTasksAction} from "../api/task";
import {AppState} from "../store";
import {getPresentableTaskPriority, getPresentableTaskStatus, TaskPriority, TaskStatus} from "../api/dtos";
import {setLoadUsersTaskStatus} from "../features/settings/settingsSlice";
import UserLayout from "../components/UserLayout";

export const MyTasks = () => {
    const dispatch: any = useDispatch();
    const [searchParams] = useSearchParams();
    const allPaginatedTasks = useSelector((state: AppState) => state.task.allPaginatedTasks);
    const currentUser = useSelector((state: AppState) => state.auth.userWithToken?.user);
    const page = Number(searchParams.get('page') || "1");
    const [priorityFilter, setPriorityFilter] = useState<TaskPriority | null>(null);
    const [statusFilter, setStatusFilter] = useState<TaskStatus | null>(null);

    useEffect(() => {
        if (currentUser?.id) {
            dispatch(getAllPaginatedTasksAction({
                legalCaseIdFilter: null,
                userIdFilter: currentUser.id,
                textFilter: "",
                priorityFilter,
                statusFilter,
                page
            }));

            return () => {
                dispatch(setLoadUsersTaskStatus(null));
            };
        }
    }, [dispatch, page, priorityFilter, statusFilter, currentUser?.id]);

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        searchParams.set("page", value.toString());
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>GÖREVLERİM</h1>
            </div>
            <Table sx={{minWidth: 800}} aria-label="my tasks table">
                <TableHead>
                    <TableRow>
                        <TableCell>Görev Adı</TableCell>
                        <TableCell>Açıklama</TableCell>
                        <TableCell>Öncelik</TableCell>
                        <TableCell>Durum</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedTasks?.entity.map((task) => (
                        <TableRow key={task.id}>
                            <TableCell>{task.title}</TableCell>
                            <TableCell>{task.description}</TableCell>
                            <TableCell>{getPresentableTaskPriority(task.priority)}</TableCell>
                            <TableCell>{getPresentableTaskStatus(task.status)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {allPaginatedTasks?.totalNumberPages != null && allPaginatedTasks.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedTasks.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChange}/>
                </div>
            )}
        </UserLayout>
    );
};
