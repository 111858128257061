// ** React Imports
import {Fragment, ReactNode, SyntheticEvent, useState} from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import {styled, Theme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import MuiMenu, {MenuProps} from '@mui/material/Menu'
import MuiAvatar, {AvatarProps} from '@mui/material/Avatar'
import MuiMenuItem, {MenuItemProps} from '@mui/material/MenuItem'
import Typography, {TypographyProps} from '@mui/material/Typography'

// ** Icons Imports
import BellOutline from 'mdi-material-ui/BellOutline'

// ** Third Party Components
import PerfectScrollbarComponent from 'react-perfect-scrollbar'

// ** Styled Menu component
const Menu = styled(MuiMenu)<MenuProps>(({theme}) => ({
    '& .MuiMenu-paper': {
        width: 380,
        overflow: 'hidden',
        marginTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    '& .MuiMenu-list': {
        padding: 0
    }
}))

// ** Styled MenuItem component
const MenuItem = styled(MuiMenuItem)<MenuItemProps>(({theme}) => ({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.divider}`
}))

const styles = {
    maxHeight: 349,
    '& .MuiMenuItem-root:last-of-type': {
        border: 0
    }
}

// ** Styled PerfectScrollbar component
const PerfectScrollbar = styled(PerfectScrollbarComponent)({
    ...styles
})

// ** Styled Avatar component
const Avatar = styled(MuiAvatar)<AvatarProps>({
    width: '2.375rem',
    height: '2.375rem',
    fontSize: '1.125rem'
})

// ** Styled component for the title in MenuItems
const MenuItemTitle = styled(Typography)<TypographyProps>(({theme}) => ({
    fontWeight: 600,
    flex: '1 1 100%',
    overflow: 'hidden',
    fontSize: '0.875rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginBottom: theme.spacing(0.75)
}))

// ** Styled component for the subtitle in MenuItems
const MenuItemSubtitle = styled(Typography)<TypographyProps>({
    flex: '1 1 100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
})

const NotificationDropdown = () => {
    // ** States
    const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)

    // ** Hook
    const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

    const handleDropdownOpen = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget)
    }

    const handleDropdownClose = () => {
        setAnchorEl(null)
    }

    const ScrollWrapper = ({children}: { children: ReactNode }) => {
        if (hidden) {
            return <Box sx={{...styles, overflowY: 'auto', overflowX: 'hidden'}}>{children}</Box>
        } else {
            return (
                <PerfectScrollbar
                    options={{wheelPropagation: false, suppressScrollX: true}}>{children}</PerfectScrollbar>
            )
        }
    }

    return (
        <Fragment>
            <IconButton color='inherit' aria-haspopup='true' onClick={handleDropdownOpen}
                        aria-controls='customized-menu'>
                <BellOutline/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDropdownClose}
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <MenuItem disableRipple>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
                        <Typography sx={{fontWeight: 600}}>Bildirimler</Typography>
                        <Chip
                            size='small'
                            label='7 yeni'
                            color='primary'
                            sx={{height: 20, fontSize: '0.75rem', fontWeight: 500, borderRadius: '10px'}}
                        />
                    </Box>
                </MenuItem>
            </Menu>
        </Fragment>
    )
}

export default NotificationDropdown
