import {useDispatch, useSelector} from "react-redux";
import {getAllCompaniesAction} from "../api/settings";
import UserLayout from "../components/UserLayout";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {CreateCompanyDialog} from "./create-company/createCompanyDialog";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

export default function Companies() {
    const dispatch: any = useDispatch();

    useEffect(() => {
        dispatch(getAllCompaniesAction());
    }, [dispatch]);
    const [createCompanyDialogOpen, setCreateCompanyDialogOpen] = useState(false);

    const companies = useSelector((state: any) => state.settings.allCompaniesLookup) || [];

    const handleDialogOpen = () => setCreateCompanyDialogOpen(true);
    const handleDialogClose = () => setCreateCompanyDialogOpen(false);

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>FENERBAHÇE ŞİRKETLERİ
                </h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>


            <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                <TableHead>
                    <TableRow>
                        <TableCell>ADI</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map((company: any) => (
                        <TableRow key={company.id}>
                            <TableCell>{company.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {createCompanyDialogOpen && (
                <CreateCompanyDialog handleClose={handleDialogClose}/>
            )}
        </UserLayout>
    );
}
