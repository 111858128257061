// ** Types Import
import {Settings} from '../../../../context/settingsContext'
import {NavLink, NavSectionTitle, VerticalNavItemsType} from '../../../types'

// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import Collapse from "@mui/material/Collapse";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../../store";
import {setOpenSections} from "../../../../../../features/appData/appDataSlice";

interface Props {
    settings: Settings
    navVisible?: boolean
    groupActive: string[]
    currentActiveGroup: string[]
    verticalNavItems?: VerticalNavItemsType
    saveSettings: (values: Settings) => void
    setGroupActive: (value: string[]) => void
    setCurrentActiveGroup: (item: string[]) => void
}

const VerticalNavItems = (props: Props) => {
    const {verticalNavItems} = props;
    const openSections = useSelector((appState: AppState) => appState.appData.openSections);
    const dispatch: any = useDispatch();

    const toggleSection = (sectionTitle: string) => {
        dispatch(setOpenSections({
            ...openSections,
            [sectionTitle]: !openSections[sectionTitle]
        }));
    };

    const groupedItems: { title: NavSectionTitle | null; contents: NavLink[] }[] = [];
    let currentGroup: { title: NavSectionTitle | null; contents: NavLink[] } = {
        title: null,
        contents: []
    };

    verticalNavItems?.forEach(item => {
        if ((item as NavSectionTitle).sectionTitle) {
            if (currentGroup.title || currentGroup.contents.length > 0) {
                groupedItems.push(currentGroup);
            }

            currentGroup = {
                title: item as NavSectionTitle,
                contents: []
            };
        } else {
            currentGroup.contents.push(item as NavLink);
        }
    });

    if (currentGroup.title || currentGroup.contents.length > 0) {
        groupedItems.push(currentGroup);
    }

    return (
        <>
            {groupedItems.map((item, index) => {
                const isOpen = openSections[item.title!.sectionTitle];
                const innerToggleSection = () => toggleSection(item.title!.sectionTitle);

                return (
                    <li key={index}>
                        <VerticalNavSectionTitle
                            {...props}
                            item={item.title!}
                            isOpen={isOpen}
                            toggleSection={innerToggleSection}/>

                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                            {item.contents.map((it, key) => (
                                <VerticalNavLink
                                    {...props}
                                    key={key}
                                    item={it}
                                    toggleNavVisibility={() => {
                                        /* Implement nav visibility toggle here */
                                    }}/>
                            ))}
                        </Collapse>
                    </li>
                );
            })}
        </>
    )
}

export default VerticalNavItems;
