import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import UserLayout from "../components/UserLayout";
import {CreateTaskDialog} from "./create-task/createTaskDialog";
import {getAllPaginatedTasksAction} from "../api/task";
import {AppState} from "../store";
import {setAllPaginatedTasks} from "../features/task/taskSlice";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {
    CaseType,
    getPresentableTaskPriority,
    getPresentableTaskStatus,
    LegalCaseDTO,
    MinimalLegalCaseDTO,
    TaskPriority,
    TaskStatus
} from "../api/dtos";
import LegalCaseAction from "../actions/LegalCaseAction";
import {ChangeStatusDialog} from "./create-status/changeStatusDialog";
import {Pagination} from "@mui/lab";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function Task() {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const [createTaskDialogOpen, setCreateTaskDialogOpen] = useState(false);
    const [changeStatusDialogOpen, setChangeStatusDialogOpen] = useState(false);
    const [selectedTaskStatus, setSelectedTaskStatus] = useState<TaskStatus | null>(null);
    const [activeTaskId, setActiveTaskId] = useState<number | null>(null);
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const allPaginatedTasks = useSelector((state: AppState) => state.task.allPaginatedTasks);
    const [textFilter, setTextFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState<TaskStatus | null>(null);
    const [priorityFilter, setPriorityFilter] = useState<TaskPriority | null>(null);
    const pageRef = useRef(page);
    const textFilterRef = useRef(textFilter);
    const statusFilterRef = useRef(statusFilter);
    const priorityFilterRef = useRef(priorityFilter);

    const loadTasks = useCallback(() => {
        dispatch(getAllPaginatedTasksAction({
            legalCaseIdFilter: null,
            userIdFilter: null,
            textFilter,
            statusFilter,
            priorityFilter,
            page
        }));
    }, [page, textFilter, statusFilter, priorityFilter, dispatch]);

    useEffect(() => {
        loadTasks();
        return () => {
            dispatch(setAllPaginatedTasks(null));
        };
    }, []);

    useEffect(() => {
        if (pageRef.current !== page ||
            textFilterRef.current !== textFilter ||
            statusFilterRef.current !== statusFilter ||
            priorityFilterRef.current !== priorityFilter) {
            loadTasks();
            pageRef.current = page;
            textFilterRef.current = textFilter;
            statusFilterRef.current = statusFilter;
            priorityFilterRef.current = priorityFilter;
        }
    }, [page, textFilter, statusFilter, priorityFilter]);

    const handleDialogOpen = () => setCreateTaskDialogOpen(true);
    const handleDialogClose = () => setCreateTaskDialogOpen(false);

    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isAddNotePopupOpen, setAddNotePopupOpen] = useState(false);
    const [isAddFilePopupOpen, setAddFilePopupOpen] = useState(false);
    const [isAddNoticePopupOpen, setAddNoticePopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [isChangeStatusPopupOpen, setChangeStatusPopupOpen] = useState(false);
    const [isAddTaskPopupOpen, setAddTaskPopupOpen] = useState(false); // Yeni state
    const [activeCaseId, setActiveCaseId] = useState<number | null>(null);

    const handleEdit = (id: number) => {
        setActiveCaseId(id);
        setEditPopupOpen(true);
    };

    const handleAddNote = (id: number) => {
        setActiveCaseId(id);
        setAddNotePopupOpen(true);
    };

    const handleAddFile = (id: number) => {
        setActiveCaseId(id);
        setAddFilePopupOpen(true);
    };

    const handleAddNotice = (id: number) => {
        setActiveCaseId(id);
        setAddNoticePopupOpen(true);
    };

    const handleDelete = (id: number) => {
        setActiveCaseId(id);
        setDeletePopupOpen(true);
    };

    const handleChangeStatus = (id: number) => {
        setActiveCaseId(id);
        setChangeStatusPopupOpen(true);
    };

    const handleAddTask = (id: number) => {
        setActiveCaseId(id);
        setAddTaskPopupOpen(true);
    };

    const saveEdit = (id: number) => {
        setEditPopupOpen(false);
    };

    const saveNote = (id: number) => {
        setAddNotePopupOpen(false);
    };

    const saveFile = (id: number) => {
        setAddFilePopupOpen(false);
    };

    const saveNotice = (id: number) => {
        setAddNoticePopupOpen(false);
    };

    const deleteCase = (id: number) => {
        setDeletePopupOpen(false);
    };

    const saveTask = (id: number) => {
        setAddTaskPopupOpen(false);
    };

    const reloadFirstPage = () => {
        if (page === 1) {
            dispatch(getAllPaginatedTasksAction({
                legalCaseIdFilter: null,
                userIdFilter: null,
                textFilter,
                statusFilter,
                priorityFilter,
                page
            }));
        } else {
            navigate("/gorevler");
        }
    }

    const handleTaskAdded = () => {
        reloadFirstPage();
    }

    const handleStatusChange = (id: number, currentStatus: TaskStatus) => {
        setActiveTaskId(id);
        setSelectedTaskStatus(currentStatus);
        setChangeStatusDialogOpen(true);
    };

    const handleSaveStatus = () => {
        setActiveTaskId(null);
        setChangeStatusDialogOpen(false);
        reloadFirstPage();
    };

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => navigate("?page=" + value);

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const status = e.target.value === "" ? null : (e.target.value as TaskStatus)
        setStatusFilter(status);
    };
    const applyTextFilter = (e: { target: { value: any; }; }) => {
        const inputTextSearch = e.target.value;
        setTextFilter(inputTextSearch)
    }
    const applyPriorityFilter = (e: { target: { value: string; }; }) => {
        const priority = e.target.value === "" ? null : (e.target.value as TaskPriority)
        setPriorityFilter(priority);
    };

    const handleClearPriority = () => {
        setPriorityFilter(null);
    }

    const handleClearStatus = () => {
        setStatusFilter(null);
    }

    const statusOptions = Object.values(TaskStatus);
    const priorityOptions = Object.values(TaskPriority);

    const getCaseDetailUrl = (legalCase: LegalCaseDTO | MinimalLegalCaseDTO) => {
        switch (legalCase.type) {
            case CaseType.Law:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Investigation:
                return `/sorusturmalar/${legalCase.id}/ayrintilar`;
            case CaseType.Criminal :
                return `/ceza-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Executive:
                return `/icra-takibi/${legalCase.id}/ayrintilar`;
            default:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
        }
    }

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>GÖREVLER</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>
            {createTaskDialogOpen && (
                <CreateTaskDialog legalCaseId={null} handleClose={handleDialogClose} handleTaskAdded={handleTaskAdded}/>
            )}

            {changeStatusDialogOpen && activeTaskId != null && (
                <ChangeStatusDialog
                    taskId={activeTaskId}
                    open={changeStatusDialogOpen}
                    handleClose={() => setChangeStatusDialogOpen(false)}
                    handleSave={handleSaveStatus}
                    currentStatus={selectedTaskStatus}/>
            )}

            <Table sx={{minWidth: 800}} aria-label="task table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "12%"}}>
                            <TextField
                                style={{minWidth: "210px"}}
                                label="Görev Adı"
                                variant="outlined"
                                size="small"
                                onChange={applyTextFilter}/>
                        </TableCell>
                        <TableCell style={{width: "12%"}}/>
                        <TableCell style={{width: "12%"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="priority-label">Öncelik</InputLabel>
                                <Select
                                    style={{minWidth: "210px"}}
                                    labelId="priority-label"
                                    label="Öncelik"
                                    value={priorityFilter || ""}
                                    onChange={applyPriorityFilter}
                                    defaultValue=""
                                    endAdornment={
                                        priorityFilter && (
                                            <InputAdornment position="start" style={{marginRight: "10px"}}>
                                                <IconButton onClick={handleClearPriority}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }>
                                    {priorityOptions.map((priority) => (
                                        <MenuItem key={priority} value={priority}>
                                            {getPresentableTaskPriority(priority)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell style={{width: "12%"}}/>
                        <TableCell style={{width: "12%"}}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="status-label">Durumu</InputLabel>
                                <Select
                                    style={{minWidth: "210px"}}
                                    labelId="status-label"
                                    label="Durumu"
                                    value={statusFilter || ""}
                                    onChange={applyStatusFilter}
                                    defaultValue=""
                                    endAdornment={
                                        statusFilter && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClearStatus}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }>
                                    {statusOptions.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {getPresentableTaskStatus(status)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell style={{width: "12%"}}/>
                        <TableCell style={{width: "12%"}}/>
                    </TableRow>
                    <TableRow>
                        <TableCell>GÖREV ADI</TableCell>
                        <TableCell>AÇIKLAMA</TableCell>
                        <TableCell>ÖNCELİK</TableCell>
                        <TableCell>ATANAN KULLANICILAR</TableCell>
                        <TableCell>DURUMU</TableCell>
                        <TableCell>İLGİLİ DAVA</TableCell>
                        <TableCell>İŞLEMLER</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedTasks?.entity.map((task) => (
                        <TableRow key={task.id}>
                            <TableCell>{task.title}</TableCell>
                            <TableCell>{task.description}</TableCell>
                            <TableCell>{getPresentableTaskPriority(task.priority)}</TableCell>
                            <TableCell>
                                {task.assignees.length > 0
                                    ? task.assignees.map((user: any) => user.firstName + " " + user.lastName).join(', ')
                                    : 'Atama Yok'}
                            </TableCell>
                            <TableCell>{getPresentableTaskStatus(task.status)}</TableCell>
                            <TableCell>
                                {task.legalCase
                                    ? (
                                        <Link to={getCaseDetailUrl(task.legalCase)} className="custom-link">
                                            {task.legalCase.name}
                                        </Link>
                                    )
                                    : "-"
                                }
                            </TableCell>
                            <TableCell>
                                <LegalCaseAction
                                    caseId={task.id}
                                    onEdit={handleEdit}
                                    onAddNote={handleAddNote}
                                    onAddFile={handleAddFile}
                                    onAddNotice={handleAddNotice}
                                    onDelete={handleDelete}
                                    onChangeStatus={() => handleStatusChange(task.id, task.status)}
                                    onAddTask={handleAddTask}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {allPaginatedTasks?.totalNumberPages != null && allPaginatedTasks.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedTasks.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChange}/>
                </div>
            )}
        </UserLayout>
    );
}
