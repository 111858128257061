import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {Edit, Lock} from "@mui/icons-material";

interface UserActionProps {
    userId: number;
    onEdit: (userId: number) => void;
    onChangePassword: (userId: number) => void;
}

const UserAction: React.FC<UserActionProps> = ({
                                                   userId,
                                                   onEdit,
                                                   onChangePassword
                                               }) => {
    // TODO:this is example action
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    return (
        <div>
            <IconButton
                aria-controls={open ? 'user-action-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size='small' aria-label='settings' className='card-more-options'
                sx={{color: 'text.secondary'}}>
                <DotsVertical/>
            </IconButton>
            <Menu
                id="user-action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick(() => onEdit(userId))}>
                    <IconButton size='small' aria-label='edit' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <Edit/>
                    </IconButton>
                    Kullanıcı Düzenle
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => onChangePassword(userId))}>
                    <IconButton size='small' aria-label='change-password' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <Lock/>
                    </IconButton>
                    Şifre Güncelle
                </MenuItem>
            </Menu>
        </div>
    );
}
export default UserAction;
