import ApexChartWrapper from "../theme/@core/styles/libs/react-apexcharts";
import Grid from "@mui/material/Grid";
import UserLayout from "../components/UserLayout";
import Statistics from "../components/Statistics";

export default function Home() {
    return (
        <UserLayout>
            <ApexChartWrapper>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={12}>
                        <Statistics/>
                    </Grid>
                </Grid>
            </ApexChartWrapper>
        </UserLayout>
    );
}
