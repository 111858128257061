import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {ApexOptions} from 'apexcharts';
import Chart from 'react-apexcharts';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {getStatisticsAction} from '../api/legalCase';
import {AppState} from '../store';
import {useTheme} from '@mui/material/styles';

const Statics = () => {
    const theme = useTheme();
    const dispatch: any = useDispatch();

    const statistics = useSelector((state: AppState) => state.legalCase.statistics);

    useEffect(() => {
        dispatch(getStatisticsAction());
    }, []);

    const prosecutorCategories = statistics?.companyProsecutorStatistics?.map(stat => stat.name) || [];
    const defendantCategories = statistics?.companyDefendantStatistics?.map(stat => stat.name) || [];
    const reportedEntitiesCategories = statistics?.companyReportedEntityStatistics?.map(stat => stat.name) || [];
    const userCategories = statistics?.statisticsByUser?.map(stat => stat.name) || [];
    const statusCategories = statistics?.statisticsByStatus?.map(stat => stat.name) || [];
    const prosecutorOpenCategories = statistics?.companyProsecutorOpenStatistics?.map(stat => stat.name) || [];
    const defendantOpenCategories = statistics?.companyDefendantOpenStatistics?.map(stat => stat.name) || [];
    const reportedEntitiesOpenCategories = statistics?.companyReportedEntityOpenStatistics?.map(stat => stat.name) || [];
    const userOpenCategories = statistics?.statisticsByUserOpen?.map(stat => stat.name) || [];

    const createOptions = (seriesName: string, categories: string[]): ApexOptions => ({
        chart: {parentHeightOffset: 0, toolbar: {show: false}, stacked: false},
        plotOptions: {bar: {borderRadius: 9, distributed: false, columnWidth: '40%'}},
        stroke: {width: 2, colors: [theme.palette.background.paper]},
        dataLabels: {enabled: false},
        legend: {show: true},
        xaxis: {
            categories: categories,
            tickPlacement: 'on',
            labels: {show: true},
            axisTicks: {show: false},
            axisBorder: {show: false}
        },
        yaxis: {
            show: true,
            tickAmount: 4,
            labels: {
                offsetX: -17,
                formatter: (value: number) => value.toString(),
            }
        },
        grid: {
            strokeDashArray: 7,
            padding: {top: -1, right: 0, left: -12, bottom: 5}
        },
        tooltip: {
            enabled: true,
            y: {
                formatter: (val: number) => `Dava Sayısı: ${val}`
            }
        },
        colors: [theme.palette.primary.main, '#1E90FF']
    });

    const prosecutorSeriesData = {
        name: 'Genel Davacı İstatistikleri',
        data: prosecutorCategories.map(prosecutorCategory => {
            const found = statistics?.companyProsecutorStatistics?.find(stat => stat.name === prosecutorCategory);
            return found ? found.count : 0;
        })
    };

    const defendantSeriesData = {
        name: 'Davalı İstatistikleri',
        data: defendantCategories.map(defendantCategory => {
            const found = statistics?.companyDefendantStatistics?.find(stat => stat.name === defendantCategory);
            return found ? found.count : 0;
        })
    };

    const reportedEntitiesData = {
        name: 'İhbar Olunan İstatistikleri',
        data: reportedEntitiesCategories.map(reportedEntitiesCategory => {
            const found = statistics?.companyReportedEntityStatistics?.find(stat => stat.name === reportedEntitiesCategory);
            return found ? found.count : 0;
        })
    };

    const userData = {
        name: 'Kullanıcı İstatistikleri',
        data: userCategories.map(userCategory => {
            const found = statistics?.statisticsByUser?.find(stat => stat.name === userCategory);
            return found ? found.count : 0;
        })
    };

    const statusData = {
        name: 'Dava Durumları',
        data: statusCategories.map(statusCategory => {
            const found = statistics?.statisticsByStatus?.find(stat => stat.name === statusCategory);
            return found ? found.count : 0;
        })
    };

    const prosecutorOpenSeriesData = {
        name: 'Açık Davacı İstatistikleri',
        data: prosecutorOpenCategories.map(prosecutorOpenCategory => {
            const found = statistics?.companyProsecutorOpenStatistics?.find(stat => stat.name === prosecutorOpenCategory);
            return found ? found.count : 0;
        })
    };

    const defendantOpenSeriesData = {
        name: 'Açık Davalı İstatistikleri',
        data: defendantOpenCategories.map(defendantOpenCategory => {
            const found = statistics?.companyDefendantOpenStatistics?.find(stat => stat.name === defendantOpenCategory);
            return found ? found.count : 0;
        })
    };

    const reportedEntitiesOpenData = {
        name: 'Açık Ceza Davaları İstatistikleri',
        data: reportedEntitiesOpenCategories.map(reportedEntitiesOpenCategory => {
            const found = statistics?.companyReportedEntityOpenStatistics?.find(stat => stat.name === reportedEntitiesOpenCategory);
            return found ? found.count : 0;
        })
    };

    const userOpenData = {
        name: 'Kullanıcıların Açık Dava İstatistikleri',
        data: userOpenCategories.map(userOpenCategory => {
            const found = statistics?.statisticsByUserOpen?.find(stat => stat.name === userOpenCategory);
            return found ? found.count : 0;
        })
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={12}>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            Davacı İstatistikleri
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Bu grafik, genel ve açık davacı istatistiklerini gösterir
                        </Typography>
                        <Chart
                            type="bar"
                            height={300}
                            options={createOptions("null", prosecutorCategories)}
                            series={[prosecutorSeriesData, prosecutorOpenSeriesData]}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            {defendantSeriesData.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Bu grafik, sanık olan şirketlerin dava sayısını temsil eder.
                        </Typography>
                        <Chart
                            type="bar"
                            height={300}
                            options={createOptions(defendantSeriesData.name, defendantCategories)}
                            series={[defendantSeriesData, defendantOpenSeriesData]}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            {reportedEntitiesData.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Bu grafik, rapor edilen şirketlerin istatistiklerini gösterir.
                        </Typography>
                        <Chart
                            type="bar"
                            height={300}
                            options={createOptions(reportedEntitiesData.name, reportedEntitiesCategories)}
                            series={[reportedEntitiesData, reportedEntitiesOpenData]}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            {userData.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Bu grafik, kullanıcılar tarafından oluşturulan davaların dağılımını sunar.
                        </Typography>
                        <Chart
                            type="bar"
                            height={300}
                            options={createOptions(userData.name, userCategories)}
                            series={[userData, userOpenData]}/>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom>
                            {statusData.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            Bu grafik, davaların durumlarına göre dağılımını gösterir.
                        </Typography>
                        <Chart
                            type="bar"
                            height={300}
                            options={createOptions(statusData.name, statusCategories)}
                            series={[statusData]}/>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default Statics;
