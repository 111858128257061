import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Autocomplete, Pagination} from "@mui/lab";
import Box from "@mui/material/Box";
import {getAllCourtHousesAction, getCourtsAction, getLoadCitiesAction} from "../api/settings";
import {AppState} from "../store";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {CreateCourt} from "./create-court/createCourtDialog";
import {getPresentableCourtType} from "../api/dtos";
import FormControl from "@mui/material/FormControl";
import {
    setAllPaginatedCourts,
    setLoadCitiesTaskStatus,
    setLoadCourtHousesTaskStatus
} from "../features/settings/settingsSlice";
import TextField from "@mui/material/TextField";
import {Option} from "../shared/utils";

export const Courts = () => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const dispatch: any = useDispatch();
    const [courtHouse, setCourtHouse] = useState<number | null>(null);
    const [city, setCity] = useState<number | null>(null);
    const [createCourtDialogOpen, setCreateCourtDialogOpen] = useState(false);
    const allCourtHouses = useSelector((state: AppState) => state.settings.allCourtHouses);
    const allCities = useSelector((state: AppState) => state.settings.allCitiesLookup);
    const courts = useSelector((state: AppState) => state.settings.allPaginatedCourts);

    useEffect(() => {
        dispatch(getAllCourtHousesAction());
        dispatch(getLoadCitiesAction());
        return () => {
            dispatch(setLoadCourtHousesTaskStatus(null));
            dispatch(setLoadCitiesTaskStatus(null));
            dispatch(setAllPaginatedCourts(null));
        }
    }, [dispatch]);

    useEffect(() => {
        if (courtHouse && city) {
            dispatch(getCourtsAction({courtHouseId: courtHouse, page}));
        }
    }, [dispatch, courtHouse, city, page]);

    const handleDialogOpen = () => setCreateCourtDialogOpen(true);
    const handleDialogClose = () => setCreateCourtDialogOpen(false);
    const navigate = useNavigate();

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        navigate("?page=" + value);
    };

    const handleCourtHouseChange = (_: React.SyntheticEvent, newValue: Option | null) => {
        if (newValue?.value) {
            setCourtHouse(newValue.value);
            dispatch(getCourtsAction({courtHouseId: Number(newValue.value), page}));
            return;
        }

        dispatch(setAllPaginatedCourts(null));
    };

    const handleCityChange = (_: React.SyntheticEvent, newValue: Option | null) => {
        if (newValue?.value) {
            setCity(newValue.value);
            setCourtHouse(null);
            dispatch(setAllPaginatedCourts(null));
            return;
        }

        setCity(null);
        setCourtHouse(null);
        dispatch(setAllPaginatedCourts(null));
    };

    const courtHouseOptions: Option[] = allCourtHouses?.filter(
        (allCourtHouse) => allCourtHouse.city.plateId === city
    ).map((courtHouse) => ({
        value: courtHouse.id,
        label: courtHouse.name
    })) || [];

    const cityOptions: Option[] = allCities?.map((city) => ({
        value: city.id,
        label: city.value
    })) || [];

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>MAHKEME / SAVCILIK / İCRA DAİRESİ</h1>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <Autocomplete
                        onChange={handleCityChange}
                        disablePortal
                        id="city-id"
                        options={cityOptions}
                        sx={{width: 400}}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Şehir"
                                       autoComplete="new-city-id"/>
                        }/>
                </FormControl>

                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <Autocomplete
                        onChange={handleCourtHouseChange}
                        disablePortal
                        id="court-house-id"
                        disabled={!city}
                        options={courtHouseOptions}
                        sx={{width: 400}}
                        value={courtHouse ? courtHouseOptions.find(opt => opt.value === courtHouse) : null}
                        renderInput={(params) =>
                            <TextField {...params}
                                       label="Adliye"
                                       autoComplete="new-court-house-id"/>
                        }/>
                </FormControl>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            {createCourtDialogOpen && (
                <CreateCourt handleClose={handleDialogClose} page={page}/>
            )}

            <Table sx={{minWidth: 800}} aria-label='court table'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "50%"}}>ADI</TableCell>
                        <TableCell style={{width: "50%"}}>TÜRÜ</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {courts?.entity.map((court) => (
                        <TableRow key={court.id}>
                            <TableCell>{court.name}</TableCell>
                            <TableCell>{getPresentableCourtType(court.type)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {courts?.totalNumberPages != null && courts.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={courts.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChange}/>
                </div>
            )}
        </UserLayout>
    );
};
