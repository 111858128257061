import React, {useEffect, useState} from "react";
import {
    Box,
    Fab,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {downloadLegalCaseFile, getLegalCaseAction} from "../../api/legalCase";
import {setLegalCase, setLoadLegalCaseTaskStatus} from "../../features/legal-case/legalCaseSlice";
import {CaseFileInfoDTO} from "../../api/dtos";
import AddIcon from "@mui/icons-material/Add";
import {AddFileToLegalCaseDialog} from "../add-file-to-legalcase/add-file-to-legalcase";

interface FileRowProps {
    file: CaseFileInfoDTO;
    caseId: number;
}

const handleDownloadCaseFile = (file: CaseFileInfoDTO, caseId: number, dispatch: any, e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(downloadLegalCaseFile({caseId, file}));
};

// TODO: move to other file
const FileRow: React.FC<FileRowProps> = ({file, caseId}) => {
    const dispatch: any = useDispatch();
    const innerDownloadCaseFile = (e: React.MouseEvent) => handleDownloadCaseFile(file, caseId, dispatch, e)

    return (
        <TableRow>
            <TableCell>
                <a href="#" onClick={innerDownloadCaseFile} style={{
                    textDecoration: 'none',
                    color: file.ready ? '#26a3ff' : 'grey',
                    cursor: file.ready ? 'pointer' : 'default'
                }}>
                    {file.name}
                </a>
            </TableCell>
            <TableCell>{file.description}</TableCell>
        </TableRow>
    );
};

export interface LegalCaseFilesProps {
    id: number;
}

const FileTable: React.FC<{ label: string; files: CaseFileInfoDTO[]; caseId: number; }> = ({label, files, caseId}) => (
    <Grid item xs={12}>
        <Typography variant="subtitle1" fontWeight="bold">
            {label}:
        </Typography>

        {files.length > 0
            ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>DOSYA ADI</TableCell>
                                <TableCell>AÇIKLAMA</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((file, index) => (
                                <FileRow key={index} file={file} caseId={caseId}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
            : (
                <Typography variant="body1">Dosya yok</Typography>
            )}
    </Grid>
);

export const LegalCaseFiles: React.FC<LegalCaseFilesProps> = ({id}) => {
    const dispatch: any = useDispatch();
    const legalCase = useSelector((state: AppState) => state.legalCase.legalCase);
    const [isAddFilePopupOpen, setAddFilePopupOpen] = useState(false);
    const [activeCaseId, setActiveCaseId] = useState<number | null>(null);

    useEffect(() => {
        dispatch(getLegalCaseAction(id));
        return () => {
            dispatch(setLegalCase(null));
            dispatch(setLoadLegalCaseTaskStatus(null));
        };
    }, [id, dispatch]);

    const handleFileAdded = () => {
        dispatch(getLegalCaseAction(id));
    };

    const handleAddFile = (id: number) => {
        setActiveCaseId(id);
        setAddFilePopupOpen(true);
    };

    if (!legalCase) {
        return null;
    }

    const downloadMainFile = (e: React.MouseEvent) => handleDownloadCaseFile(legalCase.mainFile!, id, dispatch, e);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginLeft: "auto", marginBottom: 7}}
                    onClick={() => handleAddFile(id)}>
                    <AddIcon/>
                </Fab>
            </Box>
            <Paper elevation={3} sx={{p: 3}}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1" fontWeight="bold">Ana Dosya:</Typography>
                    <Typography variant="body1">
                        {legalCase.mainFile
                            ? (
                                <a href="#"
                                   onClick={downloadMainFile}
                                   style={{
                                       textDecoration: 'none',
                                       color: legalCase.mainFile.ready ? '#26a3ff' : 'grey',
                                       cursor: legalCase.mainFile.ready ? 'pointer' : 'default'
                                   }}>
                                    {legalCase.mainFile.name}
                                </a>)
                            : ("-")
                        }
                    </Typography>
                </Grid>
                <Grid container spacing={2}>
                    <FileTable label="Diğer Dosyalar" files={legalCase.files} caseId={legalCase.id}/>
                </Grid>
            </Paper>
            {isAddFilePopupOpen && activeCaseId && (
                <AddFileToLegalCaseDialog
                    caseId={activeCaseId}
                    onClose={() => setAddFilePopupOpen(false)}
                    onFileAdded={handleFileAdded}/>
            )}
        </>
    );
};
