import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllClientsAction} from "../api/settings";
import UserLayout from "../components/UserLayout";
import Box from "@mui/material/Box";
import {CreateClientDialog} from "./create-client/createClientDialog";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

export default function Clients() {
    const dispatch: any = useDispatch();
    const [createClientDialogOpen, setCreateClientDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(getAllClientsAction());
    }, [dispatch]);

    const handleDialogOpen = () => setCreateClientDialogOpen(true);
    const handleDialogClose = () => setCreateClientDialogOpen(false);

    const clients = useSelector((state: any) => state.settings.allClientsLookup) || [];

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>DİĞER ŞİRKETLER</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>
            {createClientDialogOpen && (
                <CreateClientDialog handleClose={handleDialogClose}/>
            )}

            <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                <TableHead>
                    <TableRow>
                        <TableCell>ADI</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients.map((client: any) => (
                        <TableRow key={client.id}>
                            <TableCell>{client.value}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </UserLayout>
    );
}
