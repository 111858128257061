import axios from "axios";
import {EndPoints} from "./EndPoints";
import {AddTaskDTO, TaskPriority, TaskStatus} from "./dtos";
import {getAppToken} from "../shared/utils";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    setAllPaginatedTasks,
    setCreateTaskTaskStatus,
    setGetAllPaginatedTasksTaskStatus,
    setUpdateTaskStatusTaskStatus
} from "../features/task/taskSlice";

export const createTaskAction = createAsyncThunk(
    'task/createTask',
    async (dto: AddTaskDTO, {dispatch, getState, rejectWithValue}) => {
        dispatch(setCreateTaskTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            await axios.post(EndPoints.task, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            dispatch(setCreateTaskTaskStatus({type: AsyncTaskStatusType.Success}));
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateTaskTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllPaginatedTasksAction = createAsyncThunk(
    'task/getAllTasks',
    async ({
               legalCaseIdFilter,
               userIdFilter,
               textFilter,
               statusFilter,
               priorityFilter,
               page
           }: {
        legalCaseIdFilter: number | null;
        userIdFilter: number | null;
        textFilter: string | null;
        statusFilter: TaskStatus | null;
        priorityFilter: TaskPriority | null;
        page: number;
    }, {dispatch, getState, rejectWithValue}) => {
        dispatch(setGetAllPaginatedTasksTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const params = new URLSearchParams({
                page: page.toString()
            });

            if (legalCaseIdFilter != null) {
                params.append("legalCaseIdFilter", legalCaseIdFilter.toString());
            }

            if (userIdFilter != null) {
                params.append("userIdFilter", userIdFilter.toString());
            }

            if (textFilter != null && textFilter !== "") {
                params.append("textFilter", textFilter);
            }

            if (statusFilter != null) {
                params.append("statusFilter", statusFilter);
            }

            if (priorityFilter != null) {
                params.append("priorityFilter", priorityFilter);
            }

            const response = await axios.get(EndPoints.task + `/tasks-by-filter?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setGetAllPaginatedTasksTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedTasks(resultValue));
                return resultValue;
            } else {
                dispatch(setGetAllPaginatedTasksTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setGetAllPaginatedTasksTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);


export const updateTaskStatusAction = createAsyncThunk(
    'task/updateTaskStatus',
    async ({
               taskId,
               newStatus
           }: {
        taskId: number;
        newStatus: string;
    }, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setUpdateTaskStatusTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.put(`${EndPoints.task}/${taskId}/update-status`, {newStatus}, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setUpdateTaskStatusTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setUpdateTaskStatusTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setUpdateTaskStatusTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);
