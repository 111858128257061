import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import Box from "@mui/material/Box";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {CreateLegalCaseDialog} from "./create-legal-case/createLegalCaseDialog";
import {AppState} from "../store";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {getLegalCasesAction} from "../api/legalCase";
import {Pagination} from "@mui/lab";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {CaseType, getPresentableLegalCaseStatus, LegalCaseDTO, LegalCaseStatus} from "../api/dtos";
import moment from "moment";
import {setAllPaginatedLegalCases} from "../features/legal-case/legalCaseSlice";
import {LitigantType} from "../api/litigantType";
import TextField from "@mui/material/TextField";
import LegalCaseAction from "../actions/LegalCaseAction";
import {
    AddNoteLegalCasePopup,
    AddNoticeLegalCasePopup,
    DeleteLegalCasePopup,
    EditLegalCasePopup
} from "./legal-case-actions/legal-case-actions";
import 'moment/locale/tr';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {AddFileToLegalCaseDialog} from "./add-file-to-legalcase/add-file-to-legalcase";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

moment.locale('tr');

export const LegalCases = () => {
    const location = useLocation();
    let caseType = CaseType.Law;
    let title = "HUKUK DAVALARI";

    switch (location.pathname) {
        case '/sorusturmalar':
            caseType = CaseType.Investigation;
            title = "Soruşturmalar"
            break;
        case '/ceza-davalari':
            caseType = CaseType.Criminal;
            title = "Ceza Davaları"
            break;
        case '/icra-takibi':
            caseType = CaseType.Executive;
            title = "İcra Takibi"
            break;
    }
    const [searchParams] = useSearchParams();

    const dispatch: any = useDispatch();
    const page = Number(searchParams.get('page') || "1");
    const [nameFilter, setNameFilter] = useState("");
    const [courtIdFilter, setCourtIdFilter] = useState<number | null>(null);
    const [fileNumberFilter, setFileNumberFilter] = useState("");
    const [statusFilter, setStatusFilter] = useState<LegalCaseStatus | null>(null);
    const [selectedLegalCaseStatus, setSelectedLegalCaseStatus] = useState<LegalCaseStatus | null>(null);
    const [courtHouseFilter, setCourtHouseFilter] = useState("");
    const statusOptions = Object.values(LegalCaseStatus);
    const pageRef = useRef(page);
    const caseTypeRef = useRef(caseType);
    const nameFilterRef = useRef(nameFilter);
    const courtIdFilterRef = useRef(courtIdFilter);
    const fileNumberFilterRef = useRef(fileNumberFilter);
    const statusFilterRef = useRef(statusFilter);
    const courtHouseFilterRef = useRef(courtHouseFilter);

    const loadLegalCases = useCallback(() => {
        dispatch(getLegalCasesAction({
            companyIdFilter: null,
            clientIdFilter: null,
            nameFilter,
            typeFilter: caseType,
            courtIdFilter,
            statusFilter,
            fileNumberFilter,
            responsibleIdFilter: null,
            courtHouseFilter: null,
            page
        }));
    }, [page, caseType, nameFilter, courtIdFilter, statusFilter, fileNumberFilter, courtHouseFilter, dispatch]);

    useEffect(() => {
        loadLegalCases();
        return () => {
            dispatch(setAllPaginatedLegalCases(null));
        }
    }, []);

    useEffect(() => {
        if (pageRef.current !== page ||
            caseTypeRef.current !== caseType ||
            nameFilterRef.current !== nameFilter ||
            courtIdFilterRef.current !== courtIdFilter ||
            fileNumberFilterRef.current !== fileNumberFilter ||
            statusFilterRef.current !== statusFilter ||
            courtHouseFilterRef.current !== courtHouseFilter) {
            loadLegalCases();
            pageRef.current = page;
            caseTypeRef.current = caseType;
            nameFilterRef.current = nameFilter;
            courtIdFilterRef.current = courtIdFilter;
            fileNumberFilterRef.current = fileNumberFilter;
            statusFilterRef.current = statusFilter;
            courtHouseFilterRef.current = courtHouseFilter;
        }
    }, [page, caseType, nameFilter, courtIdFilter, fileNumberFilter, statusFilter, courtHouseFilter]);

    useEffect(() => {
        setNameFilter("");
        setCourtIdFilter(null);
        setFileNumberFilter("");
        setStatusFilter(null);
        setSelectedLegalCaseStatus(null);
        setCourtHouseFilter("");
    }, [location.pathname]);

    const [dialogOpen, setDialogOpen] = useState(false);

    const allPaginatedLegalCases = useSelector((state: AppState) => state.legalCase.allPaginatedLegalCases);

    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);
    const navigate = useNavigate();

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => navigate("?page=" + value);

    let prosecutorsLabel = "DAVACI";
    let reportedEntitiesVisible = true;
    let defendantsLabel = "DAVALI";
    let courtLabel = "MAHKEME";
    let fileNumberLabel = "DOSYA NUMARASI";
    let caseDateLabel = "DAVA TARİHİ";
    let courtHouseLabel = "ADLİYE";
    let courtHouseVisible = true;
    let investigationDateVisible = false;
    let investigationDateLabel = "SORUŞTURMA TARİHİ"
    let statementDateVisible = false;
    let statementDateLabel = "İFADE TARİHİ";
    let followTypeLabel = "TAKİP TÜRÜ";
    let followTypeVisible = false;
    let courtVisible = true;

    switch (caseType) {
        case CaseType.Criminal:// CEZA DAVALARI
            title = "CEZA DAVALARI";
            prosecutorsLabel = "MÜŞTEKİ/KATILAN";
            reportedEntitiesVisible = false;
            defendantsLabel = "SANIK";
            courtHouseVisible = true;
            followTypeVisible = false
            courtHouseLabel = "ADLİYE"
            courtLabel = "MAHKEME";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "SUÇ TARİHİ";
            statementDateVisible = false;
            investigationDateVisible = false;
            break;
        case CaseType.Executive:// İCRA TAKİBİ
            title = "İCRA TAKİPLERİ";
            prosecutorsLabel = "ALACAKLI";
            reportedEntitiesVisible = false;
            courtHouseVisible = true;
            defendantsLabel = "BORÇLU";
            investigationDateVisible = false
            followTypeVisible = false;
            statementDateVisible = false;
            courtLabel = "İCRA DAİRESİ";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "TAKİP TARİHİ";
            courtVisible = true;
            break;
        case CaseType.Investigation:// SORUŞTURMALAR
            title = "SORUŞTURMALAR";
            prosecutorsLabel = "MÜŞTEKİ";
            reportedEntitiesVisible = false;
            courtHouseVisible = true;
            defendantsLabel = "ŞÜPHELİ";
            investigationDateVisible = false;
            followTypeVisible = false;
            courtLabel = "SAVCILIK";
            statementDateVisible = false;
            fileNumberLabel = "SORUŞTURMA NUMARASI";
            caseDateLabel = "SUÇ TARİHİ";
            courtVisible = true;
            break;
    }

    const applyNameFilter = (e: { target: { value: any; }; }) => {
        const inputNameSearch = e.target.value;
        setNameFilter(inputNameSearch)
    };

    const applyCourtFilter = (e: { target: { value: string | null }; }) => {
        const inputCourtIdSearch = e.target.value ? Number(e.target.value) : null;
        setCourtIdFilter(inputCourtIdSearch);
    };

    const applyFileNumberFilter = (e: { target: { value: string; }; }) => {
        setFileNumberFilter(e.target.value);
    };

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const status = e.target.value === "" ? null : (e.target.value as LegalCaseStatus)
        setStatusFilter(status);
    };

    const applyCourtHouseFilter = (e: { target: { value: string; }; }) => {
        setCourtHouseFilter(e.target.value);
    };

    const [isEditPopupOpen, setEditPopupOpen] = useState(false);
    const [isAddNotePopupOpen, setAddNotePopupOpen] = useState(false);
    const [isAddFilePopupOpen, setAddFilePopupOpen] = useState(false);
    const [isAddNoticePopupOpen, setAddNoticePopupOpen] = useState(false);
    const [isDeletePopupOpen, setDeletePopupOpen] = useState(false);
    const [isChangeStatusPopupOpen, setChangeStatusPopupOpen] = useState(false);
    const [isAddTaskPopupOpen, setAddTaskPopupOpen] = useState(false);
    const [activeCaseId, setActiveCaseId] = useState<number | null>(null);

    const handleEdit = (id: number) => {
        setActiveCaseId(id);
        setEditPopupOpen(true);
    };

    const handleAddNote = (id: number) => {
        setActiveCaseId(id);
        setAddNotePopupOpen(true);
    };

    const handleAddFile = (id: number) => {
        setActiveCaseId(id);
        setAddFilePopupOpen(true);
    };

    const handleAddNotice = (id: number) => {
        setActiveCaseId(id);
        setAddNoticePopupOpen(true);
    };

    const handleDelete = (id: number) => {
        setActiveCaseId(id);
        setDeletePopupOpen(true);
    };

    const saveEdit = (id: number) => {
        setEditPopupOpen(false);
    };

    const saveNote = (id: number) => {
        setAddNotePopupOpen(false);
    };

    const saveFile = (id: number) => {
        setAddFilePopupOpen(false);
    };

    const saveNotice = () => {
        setAddNoticePopupOpen(false);
    };

    const deleteCase = (id: number) => {
        setDeletePopupOpen(false);
    };
    const handleChangeStatus = (id: number, currentStatus: LegalCaseStatus) => {
        setActiveCaseId(id);
        setSelectedLegalCaseStatus(currentStatus);
        setChangeStatusPopupOpen(true); // Open Change Status popup
    };

    const handleAddTask = (id: number) => {
        setActiveCaseId(id);
        setAddTaskPopupOpen(true);
    };

    const handleClearStatus = () => setStatusFilter(null);

    const getCaseDetailUrl = (legalCase: LegalCaseDTO) => {
        switch (legalCase.type) {
            case CaseType.Law:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Investigation:
                return `/sorusturmalar/${legalCase.id}/ayrintilar`;
            case CaseType.Criminal :
                return `/ceza-davalari/${legalCase.id}/ayrintilar`;
            case CaseType.Executive:
                return `/icra-takibi/${legalCase.id}/ayrintilar`;
            default:
                return `/hukuk-davalari/${legalCase.id}/ayrintilar`;
        }
    }

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>{title}</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>

            <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "11%"}}>
                            <TextField
                                style={{minWidth: "210px"}}
                                label="Konu"
                                variant="outlined"
                                size="small"
                                onChange={applyNameFilter}/>
                        </TableCell>
                        <TableCell style={{width: "11%"}}/>
                        <TableCell style={{width: "11%"}}/>
                        {followTypeVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        <TableCell style={{width: "11%"}}>
                            <TextField
                                style={{minWidth: "210px"}}
                                label="Dosya Numarası"
                                variant="outlined"
                                size="small"
                                onChange={applyFileNumberFilter}/>
                        </TableCell>
                        {courtHouseVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        {courtVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}

                        {investigationDateVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        <TableCell style={{width: "11%"}}/>
                        {statementDateVisible && (
                            <TableCell style={{width: "11%"}}/>
                        )}
                        <TableCell>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel id="status-label">Durumu</InputLabel>
                                <Select
                                    style={{minWidth: "210px"}}
                                    labelId="status-label"
                                    label="Durumu"
                                    value={statusFilter || ""}
                                    onChange={applyStatusFilter}
                                    defaultValue=""
                                    endAdornment={
                                        statusFilter && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClearStatus}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }>
                                    {statusOptions.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {getPresentableLegalCaseStatus(status)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                        <TableCell/>
                    </TableRow>
                    <TableRow>
                        <TableCell>KONU</TableCell>
                        <TableCell>{prosecutorsLabel}</TableCell>
                        <TableCell>{defendantsLabel}</TableCell>

                        {followTypeVisible && (
                            <TableCell>{followTypeLabel}</TableCell>
                        )}

                        <TableCell>{fileNumberLabel}</TableCell>

                        {courtHouseVisible && (
                            <TableCell>{courtHouseLabel}</TableCell>
                        )}

                        {courtVisible && (
                            <TableCell>{courtLabel}</TableCell>
                        )}

                        {investigationDateVisible && (
                            <TableCell>{investigationDateLabel}</TableCell>
                        )}

                        <TableCell>{caseDateLabel}</TableCell>
                        {statementDateVisible && (
                            <TableCell>{statementDateLabel}</TableCell>
                        )}
                        <TableCell>DURUMU</TableCell>
                        <TableCell>İŞLEMLER</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedLegalCases?.entity.map((legalCase) => (
                        <TableRow key={legalCase.id}>
                            <TableCell>
                                <Link to={getCaseDetailUrl(legalCase)} className="custom-link">
                                    {legalCase.name || "-"}
                                </Link>
                            </TableCell>
                            <TableCell>{legalCase.prosecutors?.map((prosecutor) => {
                                switch (prosecutor.type as LitigantType) {
                                    case LitigantType.Company:
                                        return prosecutor.company?.value;
                                    case LitigantType.Client:
                                        return prosecutor.client?.value;
                                    case LitigantType.FreeText:
                                        return prosecutor.name;
                                }
                            }).join(', ')}</TableCell>
                            <TableCell>{legalCase.defendants?.map((defendant) => {
                                switch (defendant.type as LitigantType) {
                                    case LitigantType.Company:
                                        return defendant.company?.value;
                                    case LitigantType.Client:
                                        return defendant.client?.value;
                                    case LitigantType.FreeText:
                                        return defendant.name;
                                }
                            }).join(', ')}</TableCell>
                            {followTypeVisible && (
                                <TableCell>-</TableCell>
                            )}
                            <TableCell>{legalCase.fileNumber}</TableCell>
                            {courtHouseVisible && (
                                <TableCell>{legalCase.court.courtHouse.name}</TableCell>
                            )}

                            {courtVisible && (
                                <TableCell>{legalCase.court.name}</TableCell>
                            )}

                            {investigationDateVisible && (
                                <TableCell>
                                    {legalCase.investigationDate
                                        ? moment.unix(legalCase.investigationDate).format('DD MMMM YYYY')
                                        : "-"
                                    }
                                </TableCell>
                            )}

                            <TableCell>
                                {legalCase.caseDate
                                    ? moment.unix(legalCase.caseDate).format('DD MMMM YYYY')
                                    : "-"
                                }
                            </TableCell>
                            {statementDateVisible && (
                                <TableCell>
                                    -
                                </TableCell>
                            )}
                            <TableCell>{getPresentableLegalCaseStatus(legalCase.status)}</TableCell>
                            <TableCell>
                                <LegalCaseAction
                                    caseId={legalCase.id}
                                    onEdit={handleEdit}
                                    onAddNote={handleAddNote}
                                    onAddFile={handleAddFile}
                                    onAddNotice={handleAddNotice}
                                    onDelete={handleDelete}
                                    onChangeStatus={() => handleChangeStatus(legalCase.id, legalCase.status)}
                                    onAddTask={handleAddTask}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {dialogOpen && (
                <CreateLegalCaseDialog page={page} caseType={caseType} handleClose={handleDialogClose}/>
            )}
            {isEditPopupOpen && activeCaseId && (
                <EditLegalCasePopup caseId={activeCaseId} onClose={() => setEditPopupOpen(false)}
                                    onEditLegalCase={saveEdit}/>
            )}
            {isAddNotePopupOpen && activeCaseId && (
                <AddNoteLegalCasePopup caseId={activeCaseId} onClose={() => setAddNotePopupOpen(false)}
                                       onAddNoteLegalCase={saveNote}/>
            )}
            {isAddFilePopupOpen && activeCaseId && (
                <AddFileToLegalCaseDialog
                    caseId={activeCaseId}
                    onClose={() => setAddFilePopupOpen(false)}
                    onFileAdded={() => {
                    }}/>
            )}
            {isAddNoticePopupOpen && activeCaseId && (
                <AddNoticeLegalCasePopup
                    caseId={activeCaseId}
                    onClose={() => setAddNoticePopupOpen(false)}
                    onNoticeAdded={saveNotice}/>
            )}

            {isDeletePopupOpen && activeCaseId && (
                <DeleteLegalCasePopup caseId={activeCaseId} onClose={() => setDeletePopupOpen(false)}
                                      page={page}/>
            )}

            {allPaginatedLegalCases?.totalNumberPages != null && allPaginatedLegalCases.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedLegalCases.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChange}/>
                </div>
            )}
        </UserLayout>
    );
}
