import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {createClientAction, getAllClientsAction} from "../../api/settings";
import FormControl from "@mui/material/FormControl";
import CustomDialog from "../../components/CustomDialog";

interface CreateClientDialogProps {
    handleClose: () => void;
}

export const CreateClientDialog: React.FC<CreateClientDialogProps> = ({handleClose}) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson: any = {};
        formData.forEach((value, key) => {
            formJson[key] = value;
        });

        const result = await dispatch(createClientAction(formJson));

        if (result) {
            handleClose();
            navigate("");
            dispatch(getAllClientsAction());
        }
    };

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Şirket Ekle</DialogTitle>
            <DialogContent>
                <FormControl sx={{pb: 3, minWidth: "100%"}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="name"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"/>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit">Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
