import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {CreateUserDialog} from "./create-user/createUserDialog";
import {getUsersAction} from "../api/appUser";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Pagination} from "@mui/lab";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import {getPresentableUserRole} from "../api/dtos";
import UserAction from "../actions/UserAction";

export default function Users() {
    const [searchParams] = useSearchParams();

    const page = Number(searchParams.get('page') || "1");
    const dispatch: any = useDispatch();
    const [createUserDialogOpen, setCreateUserDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(getUsersAction({page}));
    }, [dispatch, page]);

    const allPaginatedUsers = useSelector((state: any) => state.appUser.allPaginatedUsers);
    const handleDialogOpen = () => setCreateUserDialogOpen(true);
    const handleDialogClose = () => setCreateUserDialogOpen(false);
    const navigate = useNavigate();

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        navigate("?page=" + value);
    };

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>KULLANICILAR</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>
            {createUserDialogOpen && (
                <CreateUserDialog page={page} handleClose={handleDialogClose}/>
            )}

            <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "20%"}}>E-POSTA</TableCell>
                        <TableCell style={{width: "20%"}}>ADI</TableCell>
                        <TableCell style={{width: "20%"}}>SOYADI</TableCell>
                        <TableCell style={{width: "20%"}}>ROLÜ</TableCell>
                        <TableCell style={{width: "20%"}}>İŞLEMLER</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedUsers?.entity.map((client: any) => (
                        <TableRow key={client.id}>
                            <TableCell>{client.email}</TableCell>
                            <TableCell>{client.firstName}</TableCell>
                            <TableCell>{client.lastName}</TableCell>
                            <TableCell>{getPresentableUserRole(client.role)}</TableCell>
                            <TableCell>
                                <UserAction userId={1} onEdit={userId => (1)} onChangePassword={userId => (1)}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {allPaginatedUsers?.totalNumberPages != null && allPaginatedUsers.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedUsers.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChange}/>
                </div>
            )}
        </UserLayout>
    );
}
