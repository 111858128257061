import React, {MouseEvent, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DotsVertical from "mdi-material-ui/DotsVertical";
import {AddCircle, DeleteOutlined, Edit, SwapHoriz} from "@mui/icons-material";

interface LegalCaseActionProps {
    caseId: number;
    onEdit: (caseId: number) => void;
    onAddNote: (caseId: number) => void;
    onAddFile: (caseId: number) => void;
    onAddNotice: (caseId: number) => void;
    onDelete: (caseId: number) => void;
    onChangeStatus: (caseId: number) => void;
    onAddTask: (caseId: number) => void;
}

const LegalCaseAction: React.FC<LegalCaseActionProps> = ({
                                                             caseId,
                                                             onEdit,
                                                             onAddNotice,
                                                             onAddNote,
                                                             onAddFile,
                                                             onDelete,
                                                             onChangeStatus,
                                                             onAddTask
                                                         }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (action: () => void) => {
        handleClose();
        action();
    };

    return (
        <div>
            <IconButton
                aria-controls={open ? 'action-menu' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                size='small' aria-label='settings' className='card-more-options'
                sx={{color: 'text.secondary'}}>
                <DotsVertical/>
            </IconButton>
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={() => handleMenuItemClick(() => onEdit(caseId))}>
                    <IconButton size='small' aria-label='settings' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <Edit/>
                    </IconButton>
                    Düzenle
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => onAddNote(caseId))}>
                    <IconButton size='small' aria-label='settings' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <AddCircle/>
                    </IconButton>
                    Not Ekle
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => onAddFile(caseId))}>
                    <IconButton size='small' aria-label='settings' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <AddCircle/>
                    </IconButton>
                    Dosya Ekle
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => onAddNotice(caseId))}>
                    <IconButton size='small' aria-label='settings' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <AddCircle/>
                    </IconButton>
                    Tebligat Ekle
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => onChangeStatus(caseId))}>
                    <IconButton size='small' aria-label='settings' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <SwapHoriz/>
                    </IconButton>
                    Durumu Değiştir
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => onAddTask(caseId))}>
                    <IconButton size='small' aria-label='settings' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <AddCircle/>
                    </IconButton>
                    Görev Ekle
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(() => onDelete(caseId))}>
                    <IconButton size='small' aria-label='settings' className='card-more-options'
                                sx={{color: 'text.secondary'}}>
                        <DeleteOutlined/>
                    </IconButton>
                    Sil
                </MenuItem>
            </Menu>
        </div>
    );
}

export default LegalCaseAction;
