// ** React Imports
import {ReactNode} from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'

// ** Icons Imports
import ChevronUp from 'mdi-material-ui/ChevronUp'
import ChevronDown from 'mdi-material-ui/ChevronDown'
import DotsVertical from 'mdi-material-ui/DotsVertical'

// ** Types
import {ThemeColor} from '../theme/@core/layouts/types'
import UserLayout from "../components/UserLayout";

interface DataType {
    title: string
    trend: ReactNode
    trendDir: string
    avatarText: string
    trendNumber: string
    avatarColor: ThemeColor
}

const data: DataType[] = [
    {
        trendDir: 'up',
        title: 'Hukuk Davaları',
        avatarText: 'HD',
        trendNumber: '25.8%',
        avatarColor: 'success',
        trend: <ChevronUp sx={{color: 'success.main', fontWeight: 600}}/>
    },
    {
        trendDir: 'down',
        title: 'Soruşturmalar',
        avatarText: 'S',
        trendNumber: '6.2%',
        avatarColor: 'error',
        trend: <ChevronDown sx={{color: 'error.main', fontWeight: 600}}/>
    },
    {
        title: 'Ceza Davaları',
        trendDir: 'up',
        avatarText: 'CD',
        trendNumber: '12.4%',
        avatarColor: 'warning',
        trend: <ChevronUp sx={{color: 'success.main', fontWeight: 600}}/>
    },
    {
        title: 'İcra Takibi',
        trendDir: 'down',
        avatarText: 'İT',
        trendNumber: '11.9%',
        avatarColor: 'secondary',
        trend: <ChevronDown sx={{color: 'error.main', fontWeight: 600}}/>
    },
]

const Tasks = () => {
    return (
        <UserLayout>
            <Card>
                <CardHeader
                    title='Devam Eden Görevler'
                    titleTypographyProps={{sx: {lineHeight: '1.2 !important', letterSpacing: '0.31px !important'}}}
                    action={
                        <IconButton size='small' aria-label='settings' className='card-more-options'
                                    sx={{color: 'text.secondary'}}>
                            <DotsVertical/>
                        </IconButton>
                    }/>
                <CardContent sx={{pt: theme => `${theme.spacing(2)} !important`}}>
                    {data.map((item: DataType, index: number) => {
                        return (
                            <Box
                                key={item.title}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    ...(index !== data.length - 1 ? {mb: 5.875} : {})
                                }}>
                                <Avatar
                                    sx={{
                                        width: 38,
                                        height: 38,
                                        marginRight: 3,
                                        fontSize: '1rem',
                                        color: 'common.white',
                                        backgroundColor: `${item.avatarColor}.main`
                                    }}>
                                    {item.avatarText}
                                </Avatar>

                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                    <Box sx={{marginRight: 2, display: 'flex', flexDirection: 'column'}}>
                                        <Box sx={{display: 'flex'}}>
                                            <Typography sx={{
                                                mr: 0.5,
                                                fontWeight: 600,
                                                letterSpacing: '0.25px'
                                            }}>{item.title}</Typography>
                                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                                {item.trend}
                                                <Typography
                                                    variant='caption'
                                                    sx={{
                                                        fontWeight: 600,
                                                        lineHeight: 1.5,
                                                        color: item.trendDir === 'down' ? 'error.main' : 'success.main'
                                                    }}
                                                >
                                                    {item.trendNumber}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })}
                </CardContent>
            </Card>
        </UserLayout>
    )
}

export default Tasks
