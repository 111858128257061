import axios from "axios";
import {DevRootURL, EndPoints} from "./EndPoints";
import {
    AddEditClientDTO,
    AddEditCompanyDTO,
    AddEditCourtDTO,
    AddEditCourtHouseDTO,
    AddEditMailSettingsDTO,
    CreateLegalNoticeDTO,
    LegalNoticeStatus
} from "./dtos";
import {getAppToken} from "../shared/utils";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {
    setAllCitiesLookup,
    setAllClientsLookup,
    setAllCompaniesLookup,
    setAllCourtHouses,
    setAllCourts,
    setAllPaginatedCourtHouses,
    setAllPaginatedCourts,
    setCreateClientTaskStatus,
    setCreateCompanyTaskStatus,
    setCreateCourtTaskStatus,
    setCreateHouseTaskStatus,
    setCreateLegalNoticeTaskStatus,
    setDeleteClientTaskStatus,
    setDeleteCompanyTaskStatus,
    setEditClientTaskStatus,
    setEditCompanyTaskStatus,
    setEmailSettings,
    setGetAllClientsTaskStatus,
    setGetAllCompaniesTaskStatus,
    setLoadAllCourtsTaskStatus,
    setLoadCitiesTaskStatus,
    setLoadCourtHousesTaskStatus,
    setLoadCourtsTaskStatus,
    setLoadEmailSettingsTaskStatus,
    setUpdateEmailSettingsTaskStatus,
} from "../features/settings/settingsSlice";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {setAllPaginatedLegalNotices, setLoadLegalNoticesTaskStatus} from "../features/notices/legalNotice";
import moment from "moment";

export const createLegalCaseNoticeAction = createAsyncThunk(
    'legalCase/createLegalCaseNotice',
    async (dto: CreateLegalNoticeDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setCreateLegalNoticeTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.post(`${DevRootURL}/api/legal-notice`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, fieldErrors, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateLegalNoticeTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setCreateLegalNoticeTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    fieldErrors: fieldErrors,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateLegalNoticeTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);


export const getLegalNoticesAction = createAsyncThunk(
    'legalCase/getLegalNotices',
    async (
        {
            legalCaseId,
            page = 1,
            textFilter,
            startDateFilter,
            endDateFilter,
            statusFilter,
            userIdFilter
        }: {
            legalCaseId: number;
            page?: number;
            startDateFilter?: Date | null;
            endDateFilter?: Date | null;
            textFilter?: string | null;
            statusFilter?: LegalNoticeStatus | null;
            userIdFilter?: number | null;
        },
        {dispatch, getState, rejectWithValue}
    ) => {
        dispatch(setLoadLegalNoticesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Authentication token is missing');
            }

            const params = new URLSearchParams({
                legalCaseId: legalCaseId.toString(),
                page: page.toString(),
            });

            if (textFilter) {
                params.append('nameFilter', textFilter);
            }

            if (startDateFilter) {
                params.append("startDateFilter", moment(startDateFilter).startOf("day").toISOString());
            }

            if (endDateFilter) {
                params.append("endDateFilter", moment(endDateFilter).endOf("day").toISOString());
            }

            if (statusFilter) {
                params.append("statusFilter", statusFilter);
            }

            if (userIdFilter) {
                params.append("userIdFilter", userIdFilter.toString());
            }

            const response = await axios.get(`${EndPoints.legalNotices}/legal-notices-by-filter?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadLegalNoticesTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedLegalNotices(resultValue));
                return resultValue;
            } else {
                dispatch(setLoadLegalNoticesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadLegalNoticesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const createCourtHouseAction = createAsyncThunk(
    'settings/createCourtHouse',
    async (dto: AddEditCourtHouseDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setCreateHouseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.post(EndPoints.courtHouse, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, fieldErrors, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateHouseTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setCreateHouseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    fieldErrors: fieldErrors,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }

        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateHouseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);


export const createCourtAction = createAsyncThunk(
    'settings/createCourt',
    async (dto: AddEditCourtDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setCreateCourtTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.post(EndPoints.court, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const {code, message, fieldErrors, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateCourtTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setCreateCourtTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    fieldErrors: fieldErrors,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }

        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateCourtTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);


export const getAllCourtsAction = createAsyncThunk(
    'courts/getAllCourts',
    async (_, {dispatch, getState, rejectWithValue}) => {
        dispatch(setLoadAllCourtsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }
            const response = await axios.get(EndPoints.courts, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });
            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadAllCourtsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllCourts(resultValue));
                return resultValue;
            } else {
                dispatch(setLoadAllCourtsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadAllCourtsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllCourtHousesAction = createAsyncThunk(
    'courts/getAllCourtHouses',
    async (_, {dispatch, getState, rejectWithValue}) => {
        dispatch(setLoadCourtHousesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }
            const response = await axios.get(EndPoints.courtHouses, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });
            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadCourtHousesTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllCourtHouses(resultValue))
            } else {
                dispatch(setLoadCourtHousesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadCourtHousesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const createCompanyAction = createAsyncThunk(
    'settings/createCompany',
    async (dto: AddEditCompanyDTO, {dispatch, getState, rejectWithValue}) => {
        dispatch(setCreateCompanyTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.post(EndPoints.company, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateCompanyTaskStatus({type: AsyncTaskStatusType.Success}));
                return true;
            } else {
                dispatch(setCreateCompanyTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateCompanyTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editCompanyAction = createAsyncThunk(
    'settings/editCompany',
    async ({id, dto}: { id: number; dto: AddEditCompanyDTO }, {dispatch, getState, rejectWithValue}) => {
        dispatch(setEditCompanyTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            await axios.put(`${EndPoints.company}/${id}`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            dispatch(setEditCompanyTaskStatus({type: AsyncTaskStatusType.Success}));
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setEditCompanyTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteCompanyAction = createAsyncThunk(
    'settings/deleteCompany',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        dispatch(setDeleteCompanyTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            await axios.delete(`${EndPoints.company}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            dispatch(setDeleteCompanyTaskStatus({type: AsyncTaskStatusType.Success}));
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setDeleteCompanyTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllCompaniesAction = createAsyncThunk(
    'settings/getAllCompanies',
    async (_, {dispatch, getState, rejectWithValue}) => {
        dispatch(setGetAllCompaniesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.get(EndPoints.companies, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setAllCompaniesLookup(resultValue));
                dispatch(setGetAllCompaniesTaskStatus({type: AsyncTaskStatusType.Success}));
                return resultValue;
            } else {
                dispatch(setGetAllCompaniesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setGetAllCompaniesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const createClientAction = createAsyncThunk(
    'settings/createClient',
    async (dto: AddEditClientDTO, {dispatch, getState, rejectWithValue}) => {
        dispatch(setCreateClientTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.post(EndPoints.client, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateClientTaskStatus({type: AsyncTaskStatusType.Success}));
                return true;
            } else {
                dispatch(setCreateClientTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateClientTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const editClientAction = createAsyncThunk(
    'settings/editClient',
    async ({id, dto}: { id: number, dto: AddEditClientDTO }, {dispatch, getState, rejectWithValue}) => {
        dispatch(setEditClientTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            await axios.put(`${EndPoints.client}/${id}`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            dispatch(setEditClientTaskStatus({type: AsyncTaskStatusType.Success}));
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setEditClientTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteClientAction = createAsyncThunk(
    'settings/deleteClient',
    async (id: number, {dispatch, getState, rejectWithValue}) => {
        dispatch(setDeleteClientTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            await axios.delete(`${EndPoints.client}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            dispatch(setDeleteClientTaskStatus({type: AsyncTaskStatusType.Success}));
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setDeleteClientTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getAllClientsAction = createAsyncThunk(
    'settings/getAllClients',
    async (_, {dispatch, getState, rejectWithValue}) => {
        dispatch(setGetAllClientsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.get(EndPoints.clients, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setGetAllClientsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllClientsLookup(resultValue));
                return resultValue;
            } else {
                dispatch(setGetAllClientsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setGetAllClientsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getLoadCitiesAction = createAsyncThunk(
    'settings/getLoadCities',
    async (_, {dispatch, getState, rejectWithValue}) => {
        dispatch(setLoadCitiesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.get(EndPoints.cities, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadCitiesTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllCitiesLookup(resultValue));
                return resultValue; // Return the result value for further usage if needed
            } else {
                dispatch(setLoadCitiesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadCitiesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getCourtHousesAction = createAsyncThunk(
    'settings/getCourtHouses',
    async ({filterByName, cityPlateId, page = 1}: {
        filterByName?: string, cityPlateId?: number, roleFilter?: string, page: number
    }, {dispatch, getState, rejectWithValue}) => {
        dispatch(setLoadCourtHousesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const params = new URLSearchParams({
                filterByName: '',
                page: page.toString()
            });
            if (filterByName) {
                params.append('filterByName', filterByName);
            }
            if (cityPlateId != null) {
                params.append('cityPlateId', cityPlateId.toString());
            }

            const response = await axios.get(`${EndPoints.settings}/court-houses-by-filter?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadCourtHousesTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedCourtHouses(resultValue));
                return resultValue;
            } else {
                dispatch(setLoadCourtHousesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadCourtHousesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getCourtsAction = createAsyncThunk(
    'settings/getCourts',
    async ({courtHouseId, page = 1}: { courtHouseId: number, page: number }, {
        dispatch,
        getState,
        rejectWithValue,
        fulfillWithValue
    }) => {
        dispatch(setLoadCourtsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const params = new URLSearchParams({
                page: page.toString(),
                courtHouseId: courtHouseId.toString()
            });

            const response = await axios.get(`${EndPoints.settings}/courts-by-filter?${params.toString()}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
            });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadCourtsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedCourts(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadCourtsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadCourtsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    });

export const getLoadEmailSettingsAction = createAsyncThunk(
    'settings/getLoadEmailSettings',
    async (_, {dispatch, getState, rejectWithValue}) => {
        dispatch(setLoadEmailSettingsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.get(`${EndPoints.settings}/email`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails, resultValue} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadEmailSettingsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setEmailSettings(resultValue));
                return resultValue;
            } else {
                dispatch(setLoadEmailSettingsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadEmailSettingsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const updateEmailSettingsAction = createAsyncThunk(
    'settings/updateEmailSettings',
    async (newSettings: AddEditMailSettingsDTO, {dispatch, getState, rejectWithValue, fulfillWithValue}) => {
        dispatch(setUpdateEmailSettingsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.put(`${EndPoints.settings}/email`, newSettings, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            const {code, message, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setUpdateEmailSettingsTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(newSettings);
            } else {
                dispatch(setUpdateEmailSettingsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setUpdateEmailSettingsTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

