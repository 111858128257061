import React, {useEffect, useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from '@mui/material';
import {getPresentableTaskStatus, TaskStatus} from "../../api/dtos";
import {updateTaskStatusAction} from "../../api/task";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import {setUpdateTaskStatusTaskStatus} from "../../features/task/taskSlice";
import CustomDialog from "../../components/CustomDialog";

const fieldVariant = "outlined"

interface StatusDialogProps {
    open: boolean;
    currentStatus: TaskStatus | null;
    handleClose: () => void;
    handleSave: () => void;
    taskId: number;
}

export const ChangeStatusDialog: React.FC<StatusDialogProps> = ({
                                                                    open,
                                                                    taskId,
                                                                    currentStatus,
                                                                    handleClose,
                                                                    handleSave
                                                                }) => {
    const updateTaskStatusTaskStatus = useSelector((state: AppState) => state.task.updateTaskStatusTaskStatus)
    const initialStatus = currentStatus || TaskStatus.Created;
    const [formData, setFormData] = useState<{
        newStatus: TaskStatus
    }>({
        newStatus: initialStatus
    });

    const [errors, setErrors] = useState<FieldErrors>({});
    const dispatch: any = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setUpdateTaskStatusTaskStatus(null))
        }
    }, []);

    useEffect(() => {
        if (updateTaskStatusTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            updateTaskStatusTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [updateTaskStatusTaskStatus]);

    const handleSaveClick = async () => {
        if (formData.newStatus !== currentStatus) {
            try {
                await dispatch(updateTaskStatusAction({
                    taskId,
                    newStatus: formData.newStatus
                }));
                handleSave();
            } catch (error) {
                console.error("Güncelleme işlemi başarısız oldu: ", (error as any).message || error);
            }
        }
    };

    const handleChangeFormData = (event: any) => {
        const {name, value} = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    return (
        <CustomDialog open={open} onClose={handleClose}>
            <DialogTitle>Durumu Değiştir</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="new-status-label">Durum Seç</InputLabel>
                    <Select
                        labelId="city-label"
                        id="cityId"
                        name="newStatus"
                        label="Durum Seç"
                        fullWidth
                        variant={fieldVariant as TextFieldVariants}
                        value={formData.newStatus}
                        onChange={handleChangeFormData}>
                        {Object.values(TaskStatus).map((statusValue) => (
                            <MenuItem key={statusValue} value={statusValue}>
                                {getPresentableTaskStatus(statusValue)}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.newStatus && (
                        <ErrorText message={errors.newStatus}/>
                    )}
                </FormControl>
                {updateTaskStatusTaskStatus && updateTaskStatusTaskStatus.type === AsyncTaskStatusType.Error &&
                    updateTaskStatusTaskStatus.errorMessage && (
                        <ErrorText type="form" message={updateTaskStatusTaskStatus.errorMessage}/>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button onClick={handleSaveClick} color="primary"
                        disabled={formData.newStatus === initialStatus}>Kaydet</Button>
            </DialogActions>
        </CustomDialog>
    );
};
