import UserLayout from "../components/UserLayout";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getLoadEmailSettingsAction, updateEmailSettingsAction} from "../api/settings";
import {AppState} from "../store";
import {Alert, Button, DialogActions, FormControl, Snackbar, TextField, Typography} from "@mui/material";
import {FieldErrors} from "../shared/dtos";
import ErrorText from "../components/ErrorText";

export default function MailSettings() {
    const dispatch: any = useDispatch();
    const emailSettings = useSelector((state: AppState) => state.settings.emailSettings);
    const updateEmailSettingsTaskStatus = useSelector((state: AppState) => state.settings.updateEmailSettingsTaskStatus);
    const [errors, setErrors] = useState<FieldErrors>({});
    const [hasConfirmationError, setHasConfirmationError] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

    const [formData, setFormData] = useState({
        MAIL_HOST: "",
        MAIL_PORT: "",
        MAIL_USERNAME: "",
        MAIL_PASSWORD: "",
        MAIL_PROTOCOL: "",
        MAIL_SMTP_AUTH: "",
        MAIL_TLS_ENABLE: "",
        MAIL_DEBUG_ENABLE: "",
    });

    useEffect(() => {
        dispatch(getLoadEmailSettingsAction());
    }, [dispatch]);

    useEffect(() => {
        if (emailSettings) {
            setFormData({
                MAIL_HOST: emailSettings.MAIL_HOST || "",
                MAIL_PORT: emailSettings.MAIL_PORT || "",
                MAIL_USERNAME: emailSettings.MAIL_USERNAME || "",
                MAIL_PASSWORD: emailSettings.MAIL_PASSWORD || "",
                MAIL_PROTOCOL: emailSettings.MAIL_PROTOCOL || "",
                MAIL_SMTP_AUTH: emailSettings.MAIL_SMTP_AUTH || "",
                MAIL_TLS_ENABLE: emailSettings.MAIL_TLS_ENABLE || "",
                MAIL_DEBUG_ENABLE: emailSettings.MAIL_DEBUG_ENABLE || "",
            });
        }
    }, [emailSettings]);

    useEffect(() => {
        if (updateEmailSettingsTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            updateEmailSettingsTaskStatus.fieldErrors.forEach((error) => {
                updatedErrors[error.field] = error.message;
            });
            setErrors(updatedErrors);
        }
    }, [updateEmailSettingsTaskStatus]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        const result = await dispatch(updateEmailSettingsAction({propMap: formData}));
        if (result.type === 'settings/updateEmailSettings/fulfilled') {
            setSnackbarMessage('Ayarlar başarıyla güncellendi!');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            dispatch(getLoadEmailSettingsAction());
        } else {
            setSnackbarMessage('Ayarları güncellerken bir hata oluştu.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = (reason: any) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <UserLayout>
            <Typography variant="h4" gutterBottom>
                MAİL AYARLARI
            </Typography>
            <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                <TextField
                    fullWidth
                    label="Mail Sunucusu"
                    name="MAIL_HOST"
                    value={formData.MAIL_HOST}
                    onChange={handleChange}/>
                {errors.MAIL_HOST && <ErrorText message={errors.MAIL_HOST}/>}
            </FormControl>

            <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                <TextField
                    fullWidth
                    label="Mail Port Numarası"
                    name="MAIL_PORT"
                    value={formData.MAIL_PORT}
                    onChange={handleChange}/>
                {errors.MAIL_PORT && <ErrorText message={errors.MAIL_PORT}/>}
            </FormControl>

            <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                <TextField
                    fullWidth
                    label="Mail Kullanıcı Adı"
                    name="MAIL_USERNAME"
                    autoComplete="new-email"
                    value={formData.MAIL_USERNAME}
                    onChange={handleChange}/>
                {errors.MAIL_USERNAME && <ErrorText message={errors.MAIL_USERNAME}/>}
            </FormControl>

            <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                <TextField
                    fullWidth
                    label="Mail Şifresi"
                    name="MAIL_PASSWORD"
                    autoComplete="new-password"
                    value={formData.MAIL_PASSWORD}
                    onChange={handleChange}/>
                {errors.MAIL_PASSWORD && <ErrorText message={errors.MAIL_PASSWORD}/>}
            </FormControl>

            <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                <TextField
                    fullWidth
                    label="Mail Protokolü"
                    name="MAIL_PROTOCOL"
                    value={formData.MAIL_PROTOCOL}
                    onChange={handleChange}/>
                {errors.MAIL_PROTOCOL && <ErrorText message={errors.MAIL_PROTOCOL}/>}
            </FormControl>

            <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                <TextField
                    fullWidth
                    label="SMTP Kimlik Doğrulama"
                    name="MAIL_SMTP_AUTH"
                    value={formData.MAIL_SMTP_AUTH}
                    onChange={handleChange}/>
                {errors.MAIL_SMTP_AUTH && <ErrorText message={errors.MAIL_SMTP_AUTH}/>}
            </FormControl>

            <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                <TextField
                    fullWidth
                    label="TLS Etkin"
                    name="MAIL_TLS_ENABLE"
                    value={formData.MAIL_TLS_ENABLE}
                    onChange={handleChange}/>
                {errors.MAIL_TLS_ENABLE && <ErrorText message={errors.MAIL_TLS_ENABLE}/>}
            </FormControl>

            <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                <TextField
                    fullWidth
                    label="Hata Ayıklama Modu"
                    name="MAIL_DEBUG_ENABLE"
                    value={formData.MAIL_DEBUG_ENABLE}
                    onChange={handleChange}/>
                {errors.MAIL_DEBUG_ENABLE && <ErrorText message={errors.MAIL_DEBUG_ENABLE}/>}
            </FormControl>

            {updateEmailSettingsTaskStatus?.type === "Error" && (
                <Typography style={{color: "red"}}>
                    Hata: {updateEmailSettingsTaskStatus.errorMessage}
                </Typography>
            )}

            <DialogActions>
                <Button onClick={handleSubmit} disabled={hasConfirmationError}>
                    Güncelle
                </Button>
            </DialogActions>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{
                        width: '100%',
                        backgroundColor: '#003087',
                        color: '#FFD700'
                    }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </UserLayout>
    );
}
