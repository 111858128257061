import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";
import {AppUserLookupDTO, PaginatedEntityList, UserDTO} from "../../api/dtos";

export interface AppUserState {
    loadAllUsersLookupTaskStatus: AsyncTaskStatus | null;
    createUserTaskStatus: AsyncTaskStatus | null;
    allPaginatedUsers: PaginatedEntityList<UserDTO> | null;
    allUsersLookup: AppUserLookupDTO[] | null;
}

const initialState: AppUserState = {
    createUserTaskStatus: null,
    loadAllUsersLookupTaskStatus: null,
    allPaginatedUsers: null,
    allUsersLookup: null
};

const appUserSlice = createSlice({
    name: 'appUser',
    initialState,
    reducers: {
        setAllUsersLookup: (state, action: PayloadAction<AppUserLookupDTO[] | null>) => {
            state.allUsersLookup = action.payload;
        },
        setCreateUserTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.createUserTaskStatus = action.payload;
        },
        setGetAllUsersLookupTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllUsersLookupTaskStatus = action.payload;
        },
        setAllPaginatedUsers: (state, action: PayloadAction<PaginatedEntityList<UserDTO> | null>) => {
            state.allPaginatedUsers = action.payload;
        }
    }
});

export const {
    setCreateUserTaskStatus,
    setGetAllUsersLookupTaskStatus,
    setAllUsersLookup,
    setAllPaginatedUsers
} = appUserSlice.actions;

export default appUserSlice.reducer;
