import React from "react";
import {useSelector} from "react-redux";
import {Grid, Paper, Typography} from "@mui/material";
import UserLayout from "../components/UserLayout";

const ProfileDetailsRow: React.FC<{ label: string; value: string | number }> = ({label, value}) => (
    <Grid item xs={6} container spacing={1}>
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold" display="inline">
                {label}:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {value || "Veri yok"}
            </Typography>
        </Grid>
    </Grid>
);

export default function Profile() {
    const user = useSelector((state: any) => state.auth.userWithToken.user);

    return (
        <UserLayout>
            <Paper elevation={3} sx={{p: 3}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                            {user.firstName + " " + user.lastName} Profil Sayfası
                        </Typography>
                    </Grid>
                    <ProfileDetailsRow label="E-MAİL" value={user.email}/>
                    <ProfileDetailsRow label="ADI" value={user.firstName}/>
                    <ProfileDetailsRow label="SOYADI" value={user.lastName}/>
                    <ProfileDetailsRow label="ROLÜ" value={user.role}/>
                </Grid>
            </Paper>
        </UserLayout>
    );
}
