import AccountCogOutline from 'mdi-material-ui/AccountCogOutline'
import {VerticalNavItemsType} from '../theme/@core/layouts/types'
import BalanceIcon from '@mui/icons-material/Balance';
import GavelIcon from '@mui/icons-material/Gavel';
import TaskIcon from '@mui/icons-material/Task';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import BusinessIcon from '@mui/icons-material/Business';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

const navigation = (): VerticalNavItemsType => {
    return [
        {
            sectionTitle: 'Gösterge Paneli'
        },
        {
            title: 'Davalar',
            icon: GavelIcon as any,
            path: '/'
        },
        {
            title: 'Görevler',
            icon: TaskIcon as any,
            path: '/gosterge-paneli/gorevler'
        },
        {
            sectionTitle: 'Davalar'
        },
        {
            title: 'Hukuk Davaları',
            icon: BalanceIcon as any,
            path: '/hukuk-davalari',
            openInNewTab: true
        },
        {
            title: 'Soruşturmalar',
            icon: BalanceIcon,
            path: '/sorusturmalar',
            openInNewTab: true
        },
        {
            title: 'Ceza Davaları',
            icon: BalanceIcon,
            path: '/ceza-davalari',
            openInNewTab: true
        },
        {
            title: 'İcra Takipleri',
            icon: BalanceIcon,
            path: '/icra-takibi',
            openInNewTab: true
        },
        {
            sectionTitle: 'Görevler'
        },
        {
            title: 'Görevler',
            icon: TaskAltIcon,
            path: '/gorevler'
        },
        {
            title: 'Görevlerim',
            icon: TaskAltIcon,
            path: '/gorevlerim'
        },
        {
            title: 'Tebligatlar',
            icon: TaskAltIcon,
            path: '/tebligatlar'
        },
        {
            title: 'Tebligatlarım',
            icon: TaskAltIcon,
            path: ''
        },
        {
            sectionTitle: 'Müşavirlik'
        },
        {
            title: 'Masraflar',
            icon: CurrencyLiraIcon,
            path: ''
        },
        {
            sectionTitle: 'Ayarlar'
        },
        {
            title: 'Kullanıcılar',
            icon: AccountCogOutline,
            path: '/kullanicilar'
        },
        {
            title: 'Mail Ayarları',
            icon: AlternateEmailIcon,
            path: '/mail-ayarlari'
        },
        {
            title: 'Fenerbahçe Şirketleri',
            icon: BusinessIcon,
            path: '/fenerbahce-sirketleri'
        },
        {
            title: 'Diğer Şirketler',
            icon: BusinessIcon,
            path: '/diger-sirketler'
        },
        {
            title: 'Adliyeler',
            icon: AccountBalanceIcon,
            path: '/adliyeler'
        },
        {
            title: 'Mahkeme/Savcılık/İcra Dairesi',
            icon: AccountBalanceIcon,
            path: '/mahkemeler'
        },
        {
            title: 'Şehirler',
            icon: MyLocationIcon,
            path: '/sehirler'
        },
    ]
}

export default navigation
