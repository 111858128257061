import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface AppDataState {
    menuScrollPosition: number;
    openSections: Record<string, boolean>;
}

const initialState: AppDataState = {
    menuScrollPosition: 0,
    openSections: {
        "Gösterge Paneli": true,
        "Davalar": true,
        "Görevler": true,
        "Müşavirlik": true,
        "Ayarlar": true
    }
};

const appDataSlice = createSlice({
    name: 'appData',
    initialState,
    reducers: {
        setOpenSections: (state, action: PayloadAction<Record<string, boolean>>) => {
            state.openSections = action.payload;
        },
        setMenuScrollPosition: (state, action: PayloadAction<number>) => {
            state.menuScrollPosition = action.payload;
        }
    }
});

export const {
    setOpenSections,
    setMenuScrollPosition
} = appDataSlice.actions;

export default appDataSlice.reducer;
