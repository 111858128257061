import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {useSearchParams} from "react-router-dom";
import {getLoadCitiesAction} from "../api/settings";

export default function Cities() {
    const [searchParams] = useSearchParams();
    const dispatch: any = useDispatch();

    useEffect(() => {
        dispatch(getLoadCitiesAction())
    }, [dispatch]);

    const allCitiesLookup = useSelector((state: any) => state.settings.allCitiesLookup);

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>ŞEHİRLER</h1>
                <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width: "50%"}}>PLAKA NUMARASI</TableCell>
                            <TableCell style={{width: "50%"}}>ADI</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allCitiesLookup?.map((city: any) => (
                            <TableRow key={city.id}>
                                <TableCell>{city.id}</TableCell>
                                <TableCell>{city.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </UserLayout>
    );
}
