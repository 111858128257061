// export const RootURL = 'http://localhost:8080'
export const RootURL = 'http://localhost'
export const DevRootURL = "";
export const EndPoints = {
    users: `${DevRootURL}/api/user/users`,
    user: `${DevRootURL}/api/user`,
    auth: `${DevRootURL}/auth`,
    settings: `${DevRootURL}/api/settings`,
    companies: `${DevRootURL}/api/settings/companies`,
    company: `${DevRootURL}/api/settings/company`,
    clients: `${DevRootURL}/api/settings/clients`,
    client: `${DevRootURL}/api/settings/client`,
    court: `${DevRootURL}/api/settings/court`,
    courts: `${DevRootURL}/api/settings/courts`,
    courtHouse: `${DevRootURL}/api/settings/court-house`,
    courtHouses: `${DevRootURL}/api/settings/court-houses`,
    cities: `${DevRootURL}/api/settings/cities`,
    legalCase: `${DevRootURL}/api/legal-case`,
    task: `${DevRootURL}/api/task`,
    tasks: `${DevRootURL}/api/tasks`,
    legalNotices: `${DevRootURL}/api/legal-notice`
}
