import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Navigate} from 'react-router-dom';
import {logout} from "../features/auth/authSlice";

const Logout = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.removeItem("hukuk-app-token");
        localStorage.removeItem("hukuk-app-userId");
        localStorage.removeItem("hukuk-app-token-expiry");
        localStorage.removeItem("hukuk-app-refresh-token");

        dispatch(logout());
    }, [dispatch]);

    return <Navigate to="/login" replace/>;
};

export default Logout;
