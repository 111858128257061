import {Button, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {deleteLegalCaseAction, getLegalCasesAction} from "../../api/legalCase";
import React, {useState} from "react";
import {createLegalCaseNoticeAction} from "../../api/settings";
import {LegalNoticeDTO, LegalNoticeStatus, UserInTaskDTO} from "../../api/dtos";
import FormControl from "@mui/material/FormControl";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import CustomDialog from "../../components/CustomDialog";

export interface EditLegalCasePopupProps {
    caseId: number;
    onClose: () => void;
    onEditLegalCase: (caseId: number) => void;
}

export const EditLegalCasePopup: React.FC<EditLegalCasePopupProps> = ({caseId, onClose, onEditLegalCase}) => (
    <CustomDialog open={true} onClose={onClose}>
        <DialogTitle>Dava Düzenle</DialogTitle>
        <DialogContent>
            <TextField label="Dava Konusu" fullWidth margin="normal"/>

        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>İptal</Button>
            <Button onClick={() => onEditLegalCase(caseId)}>Kaydet</Button>
        </DialogActions>
    </CustomDialog>
);

export interface AddNoteLegalCasePopupProps {
    caseId: number;
    onClose: () => void;
    onAddNoteLegalCase: (caseId: number) => void;
}

export const AddNoteLegalCasePopup: React.FC<AddNoteLegalCasePopupProps> = ({caseId, onClose, onAddNoteLegalCase}) => (
    <CustomDialog open={true} onClose={onClose}>
        <DialogTitle>Dava Notu Ekle</DialogTitle>
        <DialogContent>
            <TextField
                label="Not"
                multiline
                rows={4}
                fullWidth
                margin="normal"/>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>İptal</Button>
            <Button onClick={() => onAddNoteLegalCase(caseId)}>Kaydet</Button>
        </DialogActions>
    </CustomDialog>
);

export interface AddNoticeLegalCasePopupProps {
    caseId: number;
    onClose: () => void;
    onNoticeAdded: () => void;
}

export const AddNoticeLegalCasePopup: React.FC<AddNoticeLegalCasePopupProps> = ({
                                                                                    caseId,
                                                                                    onClose,
                                                                                    onNoticeAdded
                                                                                }) => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [dueDate, setDueDate] = useState<string>(new Date().toISOString());
    const [assignees, setAssignees] = useState<UserInTaskDTO[]>([]);
    const [status, setStatus] = useState<LegalNoticeStatus>(LegalNoticeStatus.Created);
    const dispatch = useDispatch<any>();

    const handleSubmit = async () => {
        const newNotice: LegalNoticeDTO = {
            legalCaseId: 1,
            title,
            description,
            arrivalDate: new Date().toISOString(),
            dueDate,
            assignees,
            status
        };

        try {
            await dispatch(createLegalCaseNoticeAction(newNotice));
            onNoticeAdded();
            onClose();
        } catch (error) {
            console.error("Tebligat eklerken hata oluştu:", error);
        }
    };

    return (
        <CustomDialog open={true} onClose={onClose}>
            <DialogTitle>Tebligat Ekle</DialogTitle>
            <DialogContent>
                <TextField
                    label="Tebligat Başlığı"
                    fullWidth
                    margin="normal"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}/>
                <TextField
                    label="Tebligat Açıklaması"
                    multiline
                    rows={4}
                    fullWidth
                    margin="normal"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}/>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                        <DateTimePicker
                            label="Tarih"
                            onChange={(newDate) => setDueDate(newDate ? newDate.toISOString() : '')}
                            // @ts-ignore
                            renderInput={(params: any) => <TextField {...params} fullWidth/>}/>
                    </LocalizationProvider>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button onClick={handleSubmit} color="primary">Kaydet</Button>
            </DialogActions>
        </CustomDialog>
    );
};

export interface DeleteLegalCasePopupProps {
    caseId: number;
    onClose: () => void;
    page: number;
}

export const DeleteLegalCasePopup: React.FC<DeleteLegalCasePopupProps> = ({caseId, onClose, page}) => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();

    const handleDelete = async () => {
        dispatch(deleteLegalCaseAction(caseId))
        try {
            const result = await dispatch(deleteLegalCaseAction(caseId));
            if (result) {
                onClose();

                if (page === 1) {
                    dispatch(getLegalCasesAction({
                        companyIdFilter: null,
                        clientIdFilter: null,
                        nameFilter: null,
                        typeFilter: null,
                        courtIdFilter: null,
                        statusFilter: null,
                        courtHouseFilter: null,
                        fileNumberFilter: null,
                        responsibleIdFilter: null,
                        page: 1
                    }));
                } else {
                    navigate("");
                }
            }
        } catch (error) {
            console.error("Form gönderiminde bir hata oluştu:", error);
        }
    };
    return (
        <CustomDialog open={true} onClose={onClose}>
            <DialogTitle>Dava Sil</DialogTitle>
            <DialogContent>
                Seçilen Davayı Silmek İstiyor musunuz?
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button color="error" onClick={handleDelete}>Sil</Button>
            </DialogActions>
        </CustomDialog>
    )
};
