import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AsyncTaskStatus} from "../../shared/dtos";

export const getToken = () => localStorage.getItem("hukuk-app-token") || null;
export const getRefreshToken = () => localStorage.getItem("hukuk-app-refresh-token") || null;
export const getTokenExpiry = () => localStorage.getItem("hukuk-app-token-expiry")
    ? Number(localStorage.getItem("hukuk-app-token-expiry"))
    : null;

export interface AuthState {
    userWithToken: {
        //TODO: add an interface
        user: any | null;
        token: string | null;
        refreshToken: string | null;
        tokenExpiryIn: number | null;
    } | null;
    loginTaskStatus: AsyncTaskStatus | null;
    refreshTokenTaskStatus: AsyncTaskStatus | null;
}

const initialState: AuthState = {
    userWithToken: {
        user: null,
        token: getToken(),
        refreshToken: getRefreshToken(),
        tokenExpiryIn: getTokenExpiry()
    },
    loginTaskStatus: null,
    refreshTokenTaskStatus: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticationResult: (state, action) => {
            state.userWithToken = action.payload;
        },
        setLoginTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loginTaskStatus = action.payload;
        },
        setRefreshTokenTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.refreshTokenTaskStatus = action.payload;
        },
        logout: (state) => {
            state.userWithToken = null;
            state.loginTaskStatus = null;
        }
    }
});

export const {setAuthenticationResult, setLoginTaskStatus, setRefreshTokenTaskStatus, logout} = authSlice.actions;

export default authSlice.reducer;
