import {Grid, Paper, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {getLegalCaseAction} from "../../api/legalCase";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import moment from "moment/moment";
import {CaseType, getPresentableLegalCaseStatus} from "../../api/dtos";
import {LitigantType} from "../../api/litigantType";
import {setLegalCase, setLoadLegalCaseTaskStatus} from "../../features/legal-case/legalCaseSlice";

export interface LegalCaseDetailsProps {
    id: number;
}

const LitigantList: React.FC<{ label: string; litigants: any[] }> = ({label, litigants}) => (
    <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold" display="inline">
                {label}:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {litigants?.map((litigant) => {
                    switch (litigant.type as LitigantType) {
                        case LitigantType.Company:
                            return litigant.company?.value;
                        case LitigantType.Client:
                            return litigant.client?.value;
                        case LitigantType.FreeText:
                            return litigant.name;
                        default:
                            return "Bilinmeyen";
                    }
                }).join(', ') || "Veri yok"}
            </Typography>
        </Grid>
    </Grid>
);

const CaseDetailsRow: React.FC<{ label: string; value: string | number }> = ({label, value}) => (
    <Grid item xs={6} container spacing={1}>
        <Grid item>
            <Typography variant="subtitle1" fontWeight="bold" display="inline">
                {label}:
            </Typography>
        </Grid>
        <Grid item>
            <Typography variant="body1" display="inline">
                {value}
            </Typography>
        </Grid>
    </Grid>
);

export const LegalCaseDetails: React.FC<LegalCaseDetailsProps> = ({id}) => {
    const dispatch: any = useDispatch();
    const legalCase = useSelector((state: AppState) => state.legalCase.legalCase);

    useEffect(() => {
        dispatch(getLegalCaseAction(id));
        return () => {
            dispatch(setLegalCase(null));
            dispatch(setLoadLegalCaseTaskStatus(null));
        };
    }, [id, dispatch]);

    if (!legalCase) {
        return null;
    }

    const {name, fileNumber, court, caseDate, status, prosecutors, defendants, type: caseType} = legalCase;

    let title = "DAVALAR";
    let prosecutorsLabel = "DAVACI";
    let defendantsLabel = "DAVALI";
    let courtLabel = "MAHKEME";
    let fileNumberLabel = "DOSYA NUMARASI";
    let caseDateLabel = "DAVA TARİHİ";
    let courtHouseLabel = "ADLİYE";
    let followTypeLabel = "TAKİP TÜRÜ";

    let courtHouseVisible = true;
    let investigationDateVisible = false;
    let investigationDateLabel = "SORUŞTURMA TARİHİ";
    let statementDateVisible = false;
    let statementDateLabel = "İFADE TARİHİ";
    let followTypeVisible = false;
    let courtVisible = true;

    switch (caseType) {
        case CaseType.Criminal: // CEZA DAVALARI
            title = "CEZA DAVALARI";
            prosecutorsLabel = "MÜŞTEKİ/KATILAN";
            defendantsLabel = "SANIK";
            courtHouseVisible = true;
            followTypeVisible = false;
            courtLabel = "MAHKEME";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "SUÇ TARİHİ";
            statementDateVisible = false;
            investigationDateVisible = false;
            break;
        case CaseType.Executive: // İCRA TAKİBİ
            title = "İCRA TAKİPLERİ";
            prosecutorsLabel = "ALACAKLI";
            defendantsLabel = "BORÇLU";
            courtHouseVisible = true;
            investigationDateVisible = false;
            followTypeVisible = false;
            statementDateVisible = false;
            courtLabel = "İCRA DAİRESİ";
            fileNumberLabel = "DOSYA NUMARASI";
            caseDateLabel = "TAKİP TARİHİ";
            courtVisible = true;
            break;
        case CaseType.Investigation: // SORUŞTURMALAR
            title = "SORUŞTURMALAR";
            prosecutorsLabel = "MÜŞTEKİ";
            defendantsLabel = "ŞÜPHELİ";
            courtHouseVisible = true;
            investigationDateVisible = true;
            followTypeVisible = false;
            courtLabel = "SAVCILIK";
            statementDateVisible = false;
            fileNumberLabel = "SORUŞTURMA NUMARASI";
            caseDateLabel = "SUÇ TARİHİ";
            courtVisible = true;
            break;
    }

    return (
        <Paper elevation={3} sx={{p: 3}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold">
                        {title}
                    </Typography>
                </Grid>
                <LitigantList label={prosecutorsLabel} litigants={prosecutors}/>
                <LitigantList label={defendantsLabel} litigants={defendants}/>
                <CaseDetailsRow label={fileNumberLabel} value={fileNumber || "Veri yok"}/>
                {courtHouseVisible && (
                    <CaseDetailsRow label={courtHouseLabel} value={court?.courtHouse?.name || "Veri yok"}/>
                )}
                {courtVisible && (
                    <CaseDetailsRow label={courtLabel} value={court?.name || "Veri yok"}/>
                )}
                <CaseDetailsRow
                    label={caseDateLabel}
                    value={caseDate ? moment.unix(caseDate).format('DD MMMM YYYY') : "Veri yok"}/>
                {investigationDateVisible && (
                    <CaseDetailsRow
                        label={investigationDateLabel}
                        value={caseDate ? moment.unix(caseDate).format('DD MMMM YYYY') : "Veri yok"}/>
                )}
                {statementDateVisible && (
                    <CaseDetailsRow
                        label={statementDateLabel}
                        value={caseDate ? moment.unix(caseDate).format('DD MMMM YYYY') : "Veri yok"}/>
                )}
                {followTypeVisible && (
                    <CaseDetailsRow label={followTypeLabel} value="Takip Bilgisi"/>
                )}
                <CaseDetailsRow
                    label="Durumu"
                    value={getPresentableLegalCaseStatus(status)}/>
            </Grid>
        </Paper>
    );
};
