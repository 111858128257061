import React, {useEffect, useState} from "react";
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import FileUpload from "../../components/fileUpload";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store";
import {AddCaseFileToLegalCaseDTO, AddCaseFileToLegalCaseMetadata, addFileToLegalCaseAction} from "../../api/legalCase";
import {TextFieldVariants} from "@mui/material/TextField/TextField";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {unwrapResult} from "@reduxjs/toolkit";
import CustomDialog from "../../components/CustomDialog";

interface CreateLegalCaseDialogProps {
    onClose: () => void;
    caseId: number;
    onFileAdded: () => void;
}

interface AddCreateLegalCaseFormData {
    file: string | null;
    description: string | null;
}

const fieldVariant = "outlined"

export const AddFileToLegalCaseDialog: React.FC<CreateLegalCaseDialogProps> = ({onClose, caseId, onFileAdded}) => {
    const dispatch: any = useDispatch();

    const defaultFormData: AddCreateLegalCaseFormData = {
        file: null,
        description: ''
    };

    const [formData, setFormData] = useState<AddCreateLegalCaseFormData>(defaultFormData);

    const setLegalCaseFileName = (fileName: string | null) => {
        setFormData({
            ...formData, file: fileName
        });
    }

    const handleChangeFormData = (event: any) => {
        const {name, value, type, checked} = event.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                // @ts-ignore
                [name]: Array.from(event.target.selectedOptions, (option) => option.value)
            });
        } else {
            let sideEffects = {};
            if (name === "cityId") {
                sideEffects = {
                    courtId: null,
                    courtHouseId: null
                };
            }
            if (name === "courtHouseId") {
                sideEffects = {
                    courtId: null
                };
            }
            setFormData({
                ...formData,
                [name]: value,
                ...sideEffects
            });
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const initialFormData = new FormData(event.currentTarget);

        const ddd: AddCaseFileToLegalCaseMetadata = {
            file: initialFormData.get('file') as any,
            metadata: {
                description: formData.description
            } as AddCaseFileToLegalCaseDTO
        }

        const submitFormData = new FormData();
        submitFormData.append("file", ddd.file);
        submitFormData.append("metadata", new Blob([JSON.stringify(ddd.metadata)], {type: "application/json"}))

        try {
            const result = unwrapResult(await dispatch(addFileToLegalCaseAction({
                caseId: caseId,
                dto: submitFormData
            })));
            if (result) {
                onFileAdded()
                onClose();
            }
        } catch (error) {
            console.error("Form gönderiminde bir hata oluştu:", error);
        }
    };

    const addFileToLegalCaseTaskStatus = useSelector((state: AppState) => state.legalCase.addFileToLegalCaseTaskStatus);

    const [errors, setErrors] = useState<FieldErrors>({});

    useEffect(() => {
        if (addFileToLegalCaseTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            addFileToLegalCaseTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [addFileToLegalCaseTaskStatus]);

    return (
        <CustomDialog
            open={true}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}>
            <DialogTitle>Davaya Dosya Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        name="description"
                        label="Açıklama"
                        type="text"
                        fullWidth
                        variant={fieldVariant as TextFieldVariants}
                        onChange={handleChangeFormData}/>
                    {errors.name && (
                        <ErrorText message={errors.name}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <FileUpload
                        setFileName={setLegalCaseFileName}
                        fieldName="file"
                        value={formData.file}
                        uploadButtonLabel="Davaya Dosyası Ekle"/>
                    {errors.file && (
                        <ErrorText message={errors.file}/>
                    )}
                </FormControl>
                {addFileToLegalCaseTaskStatus && addFileToLegalCaseTaskStatus.type === AsyncTaskStatusType.Error &&
                    addFileToLegalCaseTaskStatus.errorMessage && (
                        <ErrorText type="form" message={addFileToLegalCaseTaskStatus.errorMessage}/>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>İptal</Button>
                <Button type="submit" disabled={!formData.file}>Ekle</Button>
            </DialogActions>
        </CustomDialog>
    );
}
