// ** React Imports
import {ReactNode} from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import {Theme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import VerticalNavItems from "../shared";
import {useSettings} from "../theme/@core/hooks/useSettings";
import VerticalLayout from "./VerticalLayout";
import VerticalAppBarContent from '../theme/layouts/components/vertical/AppBarContent'

interface Props {
    children: ReactNode
}

const UserLayout = ({children}: Props) => {
    // ** Hooks
    const {settings, saveSettings} = useSettings()

    /**
     *  The below variable will hide the current layout menu at given screen size.
     *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
     *  You can change the screen size from which you want to hide the current layout menu.
     *  Please refer useMediaQuery() hook: https://mui.com/components/use-media-query/,
     *  to know more about what values can be passed to this hook.
     *  ! Do not change this value unless you know what you are doing. It can break the template.
     */
    const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

    const Version = () => {
        return (
            <Box sx={{mx: 'auto'}}>
                Sigun 2024
            </Box>
        )
    }

    return (
        <VerticalLayout
            hidden={hidden}
            settings={settings}
            saveSettings={saveSettings}
            verticalNavItems={VerticalNavItems()} // Navigation Items
            afterVerticalNavMenuContent={Version}
            verticalAppBarContent={(
                props: any// AppBar Content
            ) => (
                <VerticalAppBarContent
                    hidden={hidden}
                    settings={settings}
                    saveSettings={saveSettings}
                    toggleNavVisibility={props.toggleNavVisibility}/>
            )}>
            {children}
        </VerticalLayout>
    )
}

export default UserLayout
