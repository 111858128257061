import axios from "axios";
import {EndPoints} from "./EndPoints";
import {getAppToken} from "../shared/utils";
import {AsyncTaskStatusType, ResponseDTO} from "../shared/dtos";
import {AppState} from "../store";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    setAddFileToLegalCaseTaskStatus,
    setAllPaginatedLegalCases,
    setCreateLegalCaseTaskStatus,
    setLegalCase,
    setLoadLegalCasesTaskStatus,
    setLoadLegalCaseTaskStatus,
    setLoadStatisticsTaskStatus,
    setStatistics
} from "../features/legal-case/legalCaseSlice";
import {CaseFileInfoDTO, CaseType, LegalCaseStatus} from "./dtos";
import {setDeleteLegalCaseTaskStatus} from "../features/settings/settingsSlice";

export const createLegalCaseAction = createAsyncThunk(
    'legalCase/createLegalCase',
    async (dto: FormData, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setCreateLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.post(`${EndPoints.legalCase}`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });

            const {code, message, fieldErrors, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setCreateLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
                return fulfillWithValue(true);
            } else {
                dispatch(setCreateLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    fieldErrors: fieldErrors,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setCreateLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getLegalCasesAction = createAsyncThunk(
    'legalCase/getLegalCasesAction',
    async ({
               companyIdFilter = null,
               clientIdFilter = null,
               nameFilter = null,
               typeFilter = null,
               courtIdFilter = null,
               statusFilter = null,
               fileNumberFilter = null,
               responsibleIdFilter = null,
               courtHouseFilter = null,
               page = 1
           }: {
        companyIdFilter: number | null;
        clientIdFilter: number | null;
        nameFilter: string | null;
        typeFilter: CaseType | null;
        courtIdFilter: number | null;
        statusFilter: LegalCaseStatus | null;
        fileNumberFilter: string | null;
        responsibleIdFilter: number | null;
        courtHouseFilter: string | null;
        page: number;
    }, {dispatch, getState, rejectWithValue}) => {
        dispatch(setLoadLegalCasesTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const params = new URLSearchParams({
                page: page.toString(),
                typeFilter: typeFilter || CaseType.Law
            });

            if (nameFilter) {
                params.append('nameFilter', nameFilter);
            }
            if (courtIdFilter != null) {
                params.append('courtIdFilter', courtIdFilter.toString());
            }
            if (statusFilter) {
                params.append('statusFilter', statusFilter);
            }
            if (fileNumberFilter) {
                params.append('fileNumberFilter', fileNumberFilter);
            }
            if (courtHouseFilter) {
                params.append('courtHouseFilter', courtHouseFilter);
            }

            const response = await axios.get(`${EndPoints.legalCase}/legal-cases-by-filter?${params.toString()}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                });

            const {code, message, errorDetails, resultValue} = response.data;
            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadLegalCasesTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setAllPaginatedLegalCases(resultValue));
                return resultValue;
            } else {
                dispatch(setLoadLegalCasesTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadLegalCasesTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const deleteLegalCaseAction = createAsyncThunk(
    'legalCase/deleteLegalCase',
    async (caseId: number, {dispatch, getState, rejectWithValue}) => {
        dispatch(setDeleteLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.delete(`${EndPoints.legalCase}/${caseId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
            });

            const {code, message} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setDeleteLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
                return true;
            } else {
                dispatch(setDeleteLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setDeleteLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage,
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const getLegalCaseAction = createAsyncThunk(
    'legalCase/getLegalCase',
    async (id: number, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setLoadLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.get(`${EndPoints.legalCase}/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                }
            });

            const {code, message, resultValue, errorDetails} = response.data as ResponseDTO;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setLegalCase(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(setLoadLegalCaseTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: message,
                    errorDetails: errorDetails
                }));
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setLoadLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

// TODO: move to correct file
export interface AddCaseFileToLegalCaseDTO {
    description: string;
}

// TODO: move to correct file
export interface AddCaseFileToLegalCaseMetadata {
    file: File;
    metadata: AddCaseFileToLegalCaseDTO;
}

export const addFileToLegalCaseAction = createAsyncThunk(
    'legalcase/addFileToLegalCase',
    async ({caseId, dto}: { caseId: number, dto: FormData }, {dispatch, getState, rejectWithValue}) => {
        dispatch(setAddFileToLegalCaseTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            await axios.post(`${EndPoints.legalCase}/${caseId}/upload-file`, dto, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });

            dispatch(setAddFileToLegalCaseTaskStatus({type: AsyncTaskStatusType.Success}));
            return true;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';
            dispatch(setAddFileToLegalCaseTaskStatus({
                type: AsyncTaskStatusType.Error,
                errorMessage
            }));
            return rejectWithValue(errorMessage);
        }
    }
);

export const downloadLegalCaseFile = createAsyncThunk(
    'legalcase/downloadLegalCaseFile',
    async ({caseId, file}: { caseId: number, file: CaseFileInfoDTO }, {dispatch, getState, rejectWithValue}) => {
        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            const downloadUrl = `${EndPoints.legalCase}/${caseId}/download-file/${file.id}?access_token=${token}`
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.get(downloadUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                },
                responseType: 'blob'
            });

            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        } catch (error) {
            console.error('Dosya indirirken hata oluştu:', error);
        }
    }
);

export const getStatisticsAction = createAsyncThunk(
    'statistics/getStatistics',
    async (_, {dispatch, getState, rejectWithValue, fulfillWithValue}: any) => {
        dispatch(setLoadStatisticsTaskStatus({type: AsyncTaskStatusType.Loading}));

        try {
            const token = await getAppToken(dispatch, getState as () => AppState);
            if (!token) {
                throw new Error('Token is missing');
            }

            const response = await axios.get(`${EndPoints.legalCase}/statistics`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
            });

            const {code, message, resultValue, errorDetails} = response.data;

            if (code === ResponseDTO.CodeEnum.Success) {
                dispatch(setLoadStatisticsTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(setStatistics(resultValue));
                return fulfillWithValue(resultValue);
            } else {
                dispatch(
                    setLoadStatisticsTaskStatus({
                        type: AsyncTaskStatusType.Error,
                        errorMessage: message,
                        errorDetails: errorDetails,
                    })
                );
                return rejectWithValue(message);
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message || 'Unknown error';

            dispatch(
                setLoadStatisticsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage,
                })
            );
            return rejectWithValue(errorMessage);
        }
    }
);

