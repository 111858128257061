import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import UserLayout from "../components/UserLayout";
import {getLegalNoticesAction} from "../api/settings";
import {AppState} from "../store";
import {setAllPaginatedLegalNotices} from "../features/notices/legalNotice";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Pagination} from "@mui/lab";
import TextField from "@mui/material/TextField";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {tr} from "date-fns/locale";
import moment from "moment";
import {AddNoticeLegalCasePopup} from "./legal-case-actions/legal-case-actions";
import {getPresentableLegalNoticeStatus, LegalNoticeStatus} from "../api/dtos";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

moment.locale("tr");

export default function LegalNotices() {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get("page") || "1");
    const allPaginatedLegalNotices = useSelector((state: AppState) => state.notices.allPaginatedLegalNotices);
    const statusOptions = Object.values(LegalNoticeStatus);
    const [textFilter, setTextFilter] = useState("");
    const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
    const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
    const [statusFilter, setStatusFilter] = useState<LegalNoticeStatus | null>(null);
    const [userIdFilter, setUserIdFilter] = useState<number | null>(null);

    const pageRef = useRef(page);
    const textFilterRef = useRef(textFilter);
    const startDateFilterRef = useRef(startDateFilter);
    const endDateFilterRef = useRef(endDateFilter);
    const statusFilterRef = useRef(statusFilter);
    const userIdFilterRef = useRef(userIdFilter);

    const loadLegalNotices = useCallback(() => {
        dispatch(getLegalNoticesAction({
            legalCaseId: 1,
            page,
            textFilter,
            startDateFilter,
            endDateFilter,
            statusFilter,
            userIdFilter
        }));
    }, [page, textFilter, startDateFilter, endDateFilter, statusFilter, userIdFilter, dispatch]);

    useEffect(() => {
        loadLegalNotices();
        return () => {
            dispatch(setAllPaginatedLegalNotices(null));
        };
    }, []);

    useEffect(() => {
        if (
            pageRef.current !== page ||
            textFilterRef.current !== textFilter ||
            startDateFilterRef.current !== startDateFilter ||
            endDateFilterRef.current !== endDateFilter ||
            statusFilterRef.current !== statusFilter ||
            userIdFilterRef.current !== userIdFilter
        ) {
            loadLegalNotices();
            pageRef.current = page;
            textFilterRef.current = textFilter;
            startDateFilterRef.current = startDateFilter;
            endDateFilterRef.current = endDateFilter;
            statusFilterRef.current = statusFilter;
            userIdFilterRef.current = userIdFilter;
        }
    }, [page, textFilter, startDateFilter, endDateFilter, statusFilter, userIdFilter]);

    const handleDialogOpen = () => setPopupOpen(true);
    const handleDialogClose = () => setPopupOpen(false);

    const handleNoticeAdded = () => {
        if (page === 1) {
            loadLegalNotices();
        } else {
            navigate("?page=1");
        }
    };

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => navigate("?page=" + value);

    const applyTextFilter = (e: { target: { value: any; }; }) => {
        const inputTextSearch = e.target.value;
        setTextFilter(inputTextSearch);
    };

    const applyStartDateFilter = (date: Date | null) => {
        setStartDateFilter(date);
    };

    const applyEndDateFilter = (date: Date | null) => {
        setEndDateFilter(date);
    };

    const applyStatusFilter = (e: { target: { value: string; }; }) => {
        const selectedStatus = e.target.value === "" ? null : e.target.value as LegalNoticeStatus;
        setStatusFilter(selectedStatus);
    };

    const applyUserIdFilter = (e: { target: { value: string } }) => {
        const userId = e.target.value === "" ? null : Number(e.target.value);
        setUserIdFilter(userId);
    };

    const handleClearStatus = () => setStatusFilter(null);

    return (
        <UserLayout>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    p: 1,
                    m: 1,
                    borderRadius: 1,
                }}>
                <Fab
                    size="small"
                    color="primary"
                    aria-label="add"
                    sx={{marginLeft: "auto", marginBottom: 5}}
                    onClick={handleDialogOpen}>
                    <AddIcon/>
                </Fab>
            </Box>

            {isPopupOpen && (
                <AddNoticeLegalCasePopup
                    caseId={1}
                    onClose={handleDialogClose}
                    onNoticeAdded={handleNoticeAdded}/>
            )}

            <Table sx={{minWidth: 800}} aria-label="legal notices table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TextField
                                style={{minWidth: "210px"}}
                                label="Tebligat Başlığı"
                                variant="outlined"
                                size="small"
                                onChange={applyTextFilter}/>
                        </TableCell>
                        <TableCell/>
                        <TableCell/>
                        <TableCell>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
                                <DatePicker
                                    label="Başlangıç Tarihi"
                                    value={startDateFilter}
                                    onChange={applyStartDateFilter}
                                    // @ts-ignore
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth style={{minWidth: "210px"}}/>
                                    )}/>
                                <DatePicker
                                    sx={{marginLeft: "10px"}}
                                    label="Bitiş Tarihi"
                                    value={endDateFilter}
                                    onChange={applyEndDateFilter}
                                    // @ts-ignore
                                    renderInput={(params) => (
                                        <TextField {...params} fullWidth style={{minWidth: "210px"}}/>
                                    )}/>
                            </LocalizationProvider>
                        </TableCell>
                        <TableCell>
                            <FormControl fullWidth size="small">
                                <InputLabel id="statusFilter">Durum</InputLabel>
                                <Select
                                    labelId="statusFilter"
                                    value={statusFilter || ""}
                                    onChange={applyStatusFilter}
                                    label="Durumu"
                                    defaultValue=""
                                    endAdornment={
                                        statusFilter && (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClearStatus}>
                                                    <ClearIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }>
                                    {statusOptions.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {getPresentableLegalNoticeStatus(status)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>TEBLİGAT BAŞLIĞI</TableCell>
                        <TableCell>AÇIKLAMA</TableCell>
                        <TableCell>TEBLİĞ TARİHİ</TableCell>
                        <TableCell>SON TARİH</TableCell>
                        <TableCell>DURUMU</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allPaginatedLegalNotices?.entity.map((notice) => (
                        <TableRow key={notice.title}>
                            <TableCell>{notice.title}</TableCell>
                            <TableCell>{notice.description}</TableCell>
                            <TableCell>{notice.arrivalDate
                                ? moment.unix(notice.arrivalDate as any).format("DD MMMM YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell>{notice.dueDate
                                ? moment.unix(notice.dueDate as any).format("DD MMMM YYYY")
                                : "-"}
                            </TableCell>
                            <TableCell>{getPresentableLegalNoticeStatus(notice.status)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {allPaginatedLegalNotices?.totalNumberPages != null && allPaginatedLegalNotices.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={allPaginatedLegalNotices.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChange}/>
                </div>
            )}
        </UserLayout>
    );
}
