import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import UserLayout from "../components/UserLayout";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Pagination} from "@mui/lab";
import Box from "@mui/material/Box";
import {getCourtHousesAction} from "../api/settings";
import {AppState} from "../store";
import {CreateCourtHouse} from "./create-court-house/createCourtHouseDialog";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

export const CourtHouses = () => {
    const [searchParams] = useSearchParams();
    const page = Number(searchParams.get('page') || "1");
    const dispatch: any = useDispatch();
    const [createCourtHouseDialogOpen, setCreateCourtHouseDialogOpen] = useState(false);

    useEffect(() => {
        dispatch(getCourtHousesAction({filterByName: "", page}));
    }, [page]);

    const courtHouses = useSelector((state: AppState) => state.settings.allPaginatedCourtHouses);
    const handleDialogOpen = () => setCreateCourtHouseDialogOpen(true);
    const handleDialogClose = () => setCreateCourtHouseDialogOpen(false);
    const navigate = useNavigate();

    const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
        navigate("?page=" + value);
    };

    useEffect(() => {
        dispatch(getCourtHousesAction({page}));
    }, [dispatch]);

    return (
        <UserLayout>
            <div id="sidebar">
                <h1>ADLİYELER</h1>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        p: 1,
                        m: 1,
                        borderRadius: 1,
                    }}>
                    <Fab
                        size="small"
                        color="primary"
                        aria-label="add"
                        sx={{marginLeft: "auto", marginBottom: 5}}
                        onClick={handleDialogOpen}>
                        <AddIcon/>
                    </Fab>
                </Box>
            </div>
            {createCourtHouseDialogOpen && (
                <CreateCourtHouse handleClose={handleDialogClose}/>
            )}

            <Table sx={{minWidth: 800}} aria-label='criminal case table'>
                <TableHead>
                    <TableRow>
                        <TableCell style={{width: "50%"}}>ŞEHİR</TableCell>
                        <TableCell style={{width: "50%"}}>ADI</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {courtHouses?.entity.map((courtHouse) => (
                        <TableRow key={courtHouse.id}>
                            <TableCell>{courtHouse.city.name}</TableCell>
                            <TableCell>{courtHouse.name}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {courtHouses?.totalNumberPages != null && courtHouses.totalNumberPages > 1 && (
                <div style={{display: "flex", justifyContent: "center", marginTop: "10px"}}>
                    <Pagination
                        page={page}
                        count={courtHouses.totalNumberPages}
                        defaultPage={1}
                        onChange={handleChange}/>
                </div>
            )}
        </UserLayout>
    );
}
