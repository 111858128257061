import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {createUserAction, getUsersAction} from "../../api/appUser";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {AddUserDTO, getPresentableUserRole, UserRole} from "../../api/dtos";
import {AsyncTaskStatusType, FieldErrors} from "../../shared/dtos";
import ErrorText from "../../components/ErrorText";
import {AppState} from "../../store";
import {setCreateUserTaskStatus} from "../../features/app-user/appUserSlice";
import CustomDialog from "../../components/CustomDialog";

interface CreateUserDialogProps {
    handleClose: () => void;
    page: number;
}

interface CreateUserFormData {
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordConfirmation: string;
    role: string;
}

export const CreateUserDialog: React.FC<CreateUserDialogProps> = ({handleClose, page}) => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const [errors, setErrors] = useState<FieldErrors>({});
    const [hasConfirmationError, setHasConfirmationError] = useState<boolean>(false);
    const [formData, setFormData] = useState<CreateUserFormData>({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        passwordConfirmation: "",
        role: ""
    });
    const createUserTaskStatus = useSelector((state: AppState) => state.appUser.createUserTaskStatus);

    const handleChangeFormData = (event: any) => {
        const {name, value, type, checked} = event.target;

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked
            });
        } else if (type === 'select-multiple') {
            setFormData({
                ...formData,
                // @ts-ignore
                [name]: Array.from(event.target.selectedOptions, (option) => option.value)
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    useEffect(() => {
        if (formData.password === formData.passwordConfirmation) {
            setErrors((prevErrors) => {
                const {passwordConfirmation, ...rest} = prevErrors;
                return rest;
            });
            setHasConfirmationError(false);
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                passwordConfirmation: "Şifreler Uyuşmuyor."
            }));
            setHasConfirmationError(true);
        }
    }, [formData.password, formData.passwordConfirmation]);

    const convertDTO = (data: CreateUserFormData): AddUserDTO => ({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        passwordConfirmation: data.passwordConfirmation,
        role: data.role as UserRole
    })

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const result = await dispatch(createUserAction(convertDTO(formData)));

        if (result.type === 'user/createUser/fulfilled') {
            handleClose();
            if (page === 1) {
                dispatch(getUsersAction({page: 1}));
            } else {
                navigate("");
            }
        }
    };

    useEffect(() => {
        if (createUserTaskStatus?.fieldErrors) {
            const updatedErrors: FieldErrors = {};
            createUserTaskStatus?.fieldErrors.forEach((it) =>
                updatedErrors[it.field] = it.message);
            setErrors(updatedErrors);
        }
    }, [createUserTaskStatus]);

    useEffect(() => {
        return () => {
            dispatch(setCreateUserTaskStatus(null));
        };
    }, []);

    return (
        <CustomDialog
            open={true}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}>
            <DialogTitle>Kullanıcı Ekle</DialogTitle>
            <DialogContent>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="email"
                        name="email"
                        label="E-Posta"
                        type="email"
                        autoComplete="new-email"
                        fullWidth
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChangeFormData}/>
                    {errors.email && (
                        <ErrorText message={errors.email}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="firstName"
                        name="firstName"
                        label="Adı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.firstName}
                        onChange={handleChangeFormData}/>
                    {errors.firstName && (
                        <ErrorText message={errors.firstName}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="lastName"
                        name="lastName"
                        label="Soyadı"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={formData.lastName}
                        onChange={handleChangeFormData}/>
                    {errors.lastName && (
                        <ErrorText message={errors.lastName}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="password"
                        name="password"
                        label="Şifre"
                        type="password"
                        fullWidth
                        autoComplete="new-password"
                        variant="outlined"
                        value={formData.password}
                        onChange={handleChangeFormData}/>
                    {errors.password && (
                        <ErrorText message={errors.password}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <TextField
                        required
                        margin="dense"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                        label="Şifre Onay"
                        type="password"
                        fullWidth
                        variant="outlined"
                        value={formData.passwordConfirmation}
                        onChange={handleChangeFormData}/>
                    {errors.passwordConfirmation && (
                        <ErrorText message={errors.passwordConfirmation}/>
                    )}
                </FormControl>
                <FormControl fullWidth={true} sx={{mt: 2, mb: 1}}>
                    <InputLabel id="role-label">Rol</InputLabel>
                    <Select
                        required
                        labelId="role-label"
                        id="role"
                        name="role"
                        label="Rol"
                        fullWidth
                        value={formData.role}
                        onChange={handleChangeFormData}>
                        <MenuItem value={UserRole.Admin}>{getPresentableUserRole(UserRole.Admin)}</MenuItem>
                        <MenuItem value={UserRole.Manager}>{getPresentableUserRole(UserRole.Manager)}</MenuItem>
                        <MenuItem value={UserRole.Member}>{getPresentableUserRole(UserRole.Member)}</MenuItem>
                        <MenuItem value={UserRole.Guest}>{getPresentableUserRole(UserRole.Guest)}</MenuItem>
                    </Select>
                    {errors.role && (
                        <ErrorText message={errors.role}/>
                    )}
                </FormControl>
                {createUserTaskStatus?.type === AsyncTaskStatusType.Error && createUserTaskStatus.errorMessage && (
                    <ErrorText type="form" message={createUserTaskStatus.errorMessage}/>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>İptal</Button>
                <Button type="submit" disabled={hasConfirmationError}>Oluştur</Button>
            </DialogActions>
        </CustomDialog>
    );
};
