import UserLayout from "../components/UserLayout";
import Button from "@mui/material/Button";
import React from "react";
import {useDispatch} from "react-redux";
import {createCourtAction} from "../api/settings";
import {CourtType} from "../api/dtos";

export default function Settings() {
    const dispatch: any = useDispatch();
    const handleAddCourtHouse = () => {
    /*    dispatch(createCourtHouseAction({

          }))*/
    }

    const handleAddCourt = () => {
        dispatch(createCourtAction({
            name: 'Birinci',
            type: CourtType.Law,
            courtHouseId: 1
        }))
    }

    return (
        <UserLayout>
            <Button
                fullWidth
                size='small'
                variant='contained'
                sx={{marginBottom: 7}}
                onClick={handleAddCourtHouse}>
                Add Court House
            </Button>
            <Button
                fullWidth
                size='small'
                variant='contained'
                sx={{marginBottom: 7}}
                onClick={handleAddCourt}>
                Add Court
            </Button>
        </UserLayout>
    );
}
